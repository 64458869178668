import { Card, CardContent } from "@mui/material";

// assets
import { ReactComponent as StatusAbnormalIcon } from "assets/img/icon/icn_abnormal.svg";
import { ReactComponent as StatusNormalIcon } from "assets/img/icon/icn_normal.svg";

const UserCard = (props) => {
  const { userData, reports } = props;

  const calculateTotalStatus = (reports) => {
    const values = Object.values(reports);
    if (values.includes("abnormal")) return "abnormal";
    if (values.every((value) => value === "noData")) return "noData";
    if (values.every((value) => value === "normal")) return "normal";
    return "unknown";
  };

  const totalStatus = calculateTotalStatus(reports);

  const getStatusStyles = (status) => {
    switch (status) {
      case "abnormal":
        return { borderColor: "#f89898", Icon: StatusAbnormalIcon };
      case "noData":
        return { borderColor: "#ffffff", Icon: null };
      case "normal":
        return { borderColor: "#91deb4", Icon: StatusNormalIcon };
      default:
        return { borderColor: "#ffffff", Icon: null };
    }
  };

  const { borderColor, Icon } = getStatusStyles(totalStatus);

  return (
    <>
      {userData.map((item, index) => (
        <Card
          key={index}
          sx={{
            borderRadius: "20px",
            borderColor: "#bad4dd",
            backgroundColor: "#FFFFFF",
            maxWidth: "300px",
            width: "100%",
            height: "120px",
            flexDirection: "row",
            marginBottom: "20px",
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              style={{
                backgroundImage: `url(${item.portrait})`,
                display: "inline-block",
                width: "80px",
                height: "80px",
                backgroundSize: "cover",
                backgroundPosition: "center",
                borderRadius: "50%",
                marginRight: "12px",
                border: `4px solid ${borderColor}`,
                position: "relative",
              }}
            >
              {Icon && (
                <Icon
                  style={{
                    position: "absolute",
                    bottom: "-1px",
                    right: "-1px",
                    width: "24px",
                    height: "24px",
                  }}
                />
              )}
            </span>
            <div style={{ textAlign: "left" }}>
              <div
                style={{
                  marginBottom: "8px",
                  fontSize: "18px",
                  fontWeight: 600,
                  color: "#424962",
                }}
              >
                {item.name}
              </div>
              <div
                style={{
                  fontSize: "16px",
                  fontWeight: "normal",
                  color: "#737791",
                }}
              >
                {item.userName}
              </div>
            </div>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default UserCard;
