import { useEffect, useState } from "react";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { FormattedMessage, useIntl } from "react-intl";

import { useTheme } from "@mui/material";

import {
  Box,
  Typography,
  Button,
  TextField,
  Grid,
  FormHelperText,
  Skeleton,
} from "@mui/material";
import { ReactComponent as InputEditIcon } from "assets/img/icon/icn_input_edit_default.svg";
import { ReactComponent as ProfileUploadIcon } from "assets/img/icon/icn_profile_upload.svg";

import { elderInfoTitle, elderInfoData } from "./style";
import CustomDatePicker from "./components/CustomDatePicker";
import dayjs from "dayjs";

const ElderInfo = ({
  elderData,
  setElderData,
  isLoading,
  wsData,
  setWsData,
  homeGroupId,
  userId,
  isEditingElder,
  isEditingCare,
  setIsEditingElder,
  handleEditClickElder,
}) => {
  const ws = useWebSocket();
  const { token } = useAuth();
  const theme = useTheme();
  const intl = useIntl();
  const minDate = dayjs("1912-01-01");
  const maxDate = dayjs();

  const [phoneError, setPhoneError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [userNameError, setUserNameError] = useState(false);
  const [dateError, setDateError] = useState(false);

  const [emergencyContactPhoneError, setEmergencyContactPhoneError] =
    useState(false);
  const [hasError, setHasError] = useState(false); // judgment complete button
  const [savedData, setSavedData] = useState(null);

  const [formData, setFormData] = useState({
    portrait: "",
    userName: "",
    email: "",
    phone: "",
    birthday: "",
    name: "",
    contacts: [],
  });

  useEffect(() => {
    if (elderData) {
      setFormData({
        portrait: elderData.portrait,
        userName: elderData.userName,
        email: elderData.email,
        phone: elderData.phone,
        birthday: elderData.birthday,
        contacts:
          Array.isArray(elderData.contacts) && elderData.contacts.length > 0
            ? elderData.contacts
            : [{ name: "", phone: "" }],
        name: elderData.name,
      });
    }
  }, [elderData]);

  /* console.log(formData); */

  const handleInputChange = (e, setter, contactIndex = null) => {
    const value = e.target.value;

    if (contactIndex !== null) {
      if (setter === "phone") {
        if (value.trim() === "") {
          setEmergencyContactPhoneError(false);
          setHasError(false);
        } else {
          const phoneRegex = /^09\d{8}$/;
          const isValidPhone = phoneRegex.test(value);
          if (!isValidPhone) {
            setEmergencyContactPhoneError(true);
            setHasError(true);
          } else {
            setEmergencyContactPhoneError(false);
            setHasError(false);
          }
        }
      }

      setFormData((prevData) => {
        const updatedContacts = [...prevData.contacts];
        updatedContacts[contactIndex] = {
          ...updatedContacts[contactIndex],
          [setter]: value,
        };
        return { ...prevData, contacts: updatedContacts };
      });
    } else {
      setter(value);
    }
  };

  // update api
  const handleSave = () => {
    const filteredContacts = formData.contacts.filter(
      (contact) => contact.name.trim() !== "" || contact.phone.trim() !== ""
    );
    const infoUpdateData = {
      resource: `/users/${userId}`,
      verb: "update",
      accessToken: token,
      data: {
        filter: {
          homeGroupId: homeGroupId,
        },
        userData: {
          ...formData,
          contacts: filteredContacts,
        },
      },
    };

    ws.send(JSON.stringify(infoUpdateData));

    if (!hasError) {
      setSavedData(formData);
      setIsEditingElder(false);
    }
  };

  const handleCancel = () => {
    const newFormData = savedData || elderData;

    // Make sure contacts has at least one empty contact
    if (
      !Array.isArray(newFormData.contacts) ||
      newFormData.contacts.length === 0
    ) {
      newFormData.contacts = [{ name: "", phone: "" }];
    }

    setFormData(newFormData);
    setIsEditingElder(false);
    setHasError(false);
    setEmailError(false);
    setPhoneError(false);
    setUserNameError(false);
    setDateError(false);
    setEmergencyContactPhoneError(false);
  };

  useEffect(() => {
    if (wsData.updateTag === "user_create" && wsData.updateData) {
      setFormData(wsData.updateData);
    }
  }, [wsData]);

  // validate input
  const validateUserName = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({ ...prevData, userName: value }));

    if (!value.trim()) {
      setUserNameError(true);
      setHasError(true);
    } else if (value.length > 100) {
      setUserNameError(true);
      setHasError(true);
    } else {
      setUserNameError(false);
      setHasError(false);
    }
  };

  const validateDate = (newValue) => {
    const isValidDate = dayjs(newValue, "YYYY/MM/DD", true).isValid();

    if (
      !isValidDate ||
      dayjs(newValue).isBefore(minDate) ||
      dayjs(newValue).isAfter(maxDate)
    ) {
      setDateError(true);
      setHasError(true);
    } else {
      setDateError(false);
      setHasError(false);
    }
    const taipeiDate = dayjs(newValue)
      .tz("Asia/Taipei", true)
      .startOf("day")
      .format("YYYY-MM-DDTHH:mm:ss[Z]");
    setFormData((prevData) => ({
      ...prevData,
      birthday: taipeiDate,
    }));
  };

  const validateEmail = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({ ...prevData, email: value }));
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValid = emailRegex.test(value);
    setEmailError(!isValid);
    setHasError(!isValid);
  };

  const validatePhone = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({ ...prevData, phone: value }));
    if (value.trim() === "") {
      setPhoneError(false);
      setHasError(false);
      return;
    }

    const phoneRegex = /^09\d{8}$/;
    const isValid = phoneRegex.test(value);
    setPhoneError(!isValid);
    setHasError(!isValid);
  };

  // upload image
  const handlePhotoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setFormData((prevData) => ({ ...prevData, portrait: reader.result }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "32px",
          position: "relative",
          marginTop: "6px",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
            width: "120px",
          }}
        >
          {isLoading ? (
            <>
              <Skeleton variant="circular" width={120} height={120} />
            </>
          ) : (
            <>
              <Box
                component="label"
                sx={{
                  display: "inline-block",
                  width: "120px",
                  height: "120px",
                  borderRadius: "50%",
                  backgroundImage: `url(${formData.portrait})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  cursor: isEditingElder ? "pointer" : "default",
                  position: "relative",
                  "&:hover .hover-overlay": isEditingElder
                    ? {
                      display: "flex",
                    }
                    : {},
                }}
              >
                {isEditingElder && (
                  <input
                    type="file"
                    accept="image/*"
                    hidden
                    onChange={handlePhotoUpload}
                  />
                )}
                {isEditingElder && (
                  <Box
                    className="hover-overlay"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                      borderRadius: "50%",
                      flexDirection: "column",
                      color: "#fff",
                      textAlign: "center",
                      zIndex: 1,
                    }}
                  >
                    <ProfileUploadIcon
                      style={{
                        width: "40px",
                        height: "40px",
                      }}
                    />
                    <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                      <FormattedMessage id="app.careInformation.elderInfo.uploadPhoto" />
                    </Typography>
                  </Box>
                )}
              </Box>
            </>
          )}

          {isEditingElder ? (
            <TextField
              value={formData.name}
              placeholder={intl.formatMessage({ id: 'app.careInformation.elderInfo.nickName' })}
              onChange={(e) =>
                handleInputChange(e, (value) =>
                  setFormData({ ...formData, name: value })
                )
              }
              fullWidth
              variant="outlined"
              size="small"
              sx={{
                width: "120px",
                marginTop: "12px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "10px",
                  height: "44px",
                  "& fieldset": {
                    borderColor: "#eef0f2",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#bad4dd",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "1px solid #bad4dd",
                    boxShadow: "0 0 0 2px rgba(186, 212, 221, 0.25)",
                  },
                },
              }}
            />
          ) : isLoading ? (
            <Skeleton variant="text" width={120} height={44} sx={{ mb: -2 }} />
          ) : (
            <div
              style={{
                fontSize: "18px",
                fontWeight: 600,
                color: "#737791",
                marginTop: "14px",
              }}
            >
              {formData.name}
            </div>
          )}
        </Box>

        <Grid container spacing={2} sx={{ width: "100%" }}>
          {/* 姓名 */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={elderInfoTitle}><FormattedMessage id="app.careInformation.elderInfo.name" /></Typography>
            <Box sx={{ ml: 10, flexGrow: 1 }}>
              {isEditingElder ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    marginTop: "-10px",
                  }}
                >
                  <TextField
                    value={formData.userName}
                    onChange={validateUserName}
                    error={userNameError}
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "284px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "44px",
                        backgroundColor: userNameError
                          ? "#fff4f4"
                          : "transparent",
                        "& fieldset": {
                          borderColor: "#eef0f2",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#bad4dd",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: userNameError
                            ? "1px solid #E80000"
                            : "1px solid #bad4dd",
                          boxShadow: userNameError
                            ? "0px 0px 0px 2px rgba(232, 0, 0, 0.25)"
                            : "0 0 0 2px rgba(186, 212, 221, 0.25)",
                        },
                      },
                    }}
                  />
                  {userNameError && formData.userName.length > 100 && (
                    <FormHelperText
                      sx={{
                        color: "red",
                        fontSize: "14px",
                        marginTop: "-1px",
                      }}
                    >
                      <FormattedMessage id="app.errorMessage.noMoreThanWords" values={{ value: '100' }} />
                    </FormHelperText>
                  )}
                </div>
              ) : isLoading ? (
                <Skeleton
                  variant="text"
                  width="60%"
                  height={44}
                  sx={{ mt: -1.5 }}
                />
              ) : (
                <Typography sx={elderInfoData}>
                  {formData.userName || "-"}
                </Typography>
              )}
            </Box>
          </Grid>
          {/* 出生年月日 */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={elderInfoTitle}><FormattedMessage id="app.careInformation.elderInfo.dateOfBirth" /></Typography>

            <Box sx={{ ml: 4, flexGrow: 1 }}>
              {isEditingElder ? (
                <div style={{ marginTop: "-18px" }}>
                  <CustomDatePicker
                    value={formData.birthday}
                    onChange={validateDate}
                    error={dateError}
                    minDate={minDate}
                    maxDate={maxDate}
                  />
                  {dateError && (
                    <FormHelperText
                      sx={{
                        color: "red",
                        marginTop: "-1px",
                        fontSize: "14px",
                      }}
                    >
                      <FormattedMessage id="app.errorMessage.pleaseEnterTheCorrectFormat" />
                    </FormHelperText>
                  )}
                </div>
              ) : isLoading ? (
                <Skeleton
                  variant="text"
                  width="60%"
                  height={44}
                  sx={{ mt: -1.5 }}
                />
              ) : (
                <Typography sx={elderInfoData}>
                  {formData.birthday
                    ? dayjs(formData.birthday).format("YYYY / MM / DD")
                    : "-"}
                </Typography>
              )}
            </Box>
          </Grid>

          {/* 聯絡電話 */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={elderInfoTitle}><FormattedMessage id="app.careInformation.elderInfo.contactNumber" /></Typography>

            <Box sx={{ ml: 6, flexGrow: 1 }}>
              {isEditingElder ? (
                <div style={{ marginTop: "-10px" }}>
                  <TextField
                    value={formData.phone}
                    onChange={(e) => validatePhone(e)}
                    error={phoneError}
                    fullWidth
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "284px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "44px",
                        backgroundColor: phoneError ? "#fff4f4" : "transparent",
                        "& fieldset": {
                          borderColor: "#eef0f2",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#bad4dd",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: phoneError
                            ? "1px solid #E80000"
                            : "1px solid #bad4dd",
                          boxShadow: phoneError
                            ? "0px 0px 0px 2px rgba(232, 0, 0, 0.25)"
                            : "0 0 0 2px rgba(186, 212, 221, 0.25)",
                        },
                      },
                    }}
                  />
                  {phoneError && (
                    <FormHelperText
                      sx={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      <FormattedMessage id="app.errorMessage.pleaseEnterValidInformation"
                        values={{ fieldName: intl.formatMessage({ id: 'app.careInformation.elderInfo.contactNumber' }) }} />
                    </FormHelperText>
                  )}
                </div>
              ) : isLoading ? (
                <Skeleton
                  variant="text"
                  width="60%"
                  height={44}
                  sx={{ mt: -1.5 }}
                />
              ) : (
                <Typography sx={elderInfoData}>
                  {formData.phone || "-"}
                </Typography>
              )}
            </Box>
          </Grid>

          {/* 電子信箱 */}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              alignItems: "flex-start",
            }}
          >
            <Typography sx={elderInfoTitle}><FormattedMessage id="app.careInformation.elderInfo.email" /></Typography>

            <Box sx={{ ml: 6, flexGrow: 1 }}>
              {isEditingElder ? (
                <div style={{ marginTop: "-10px" }}>
                  <TextField
                    value={formData.email}
                    onChange={validateEmail}
                    fullWidth
                    variant="outlined"
                    size="small"
                    error={emailError}
                    sx={{
                      width: "284px",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        height: "44px",
                        backgroundColor: emailError ? "#fff4f4" : "transparent",
                        "& fieldset": {
                          borderColor: "#eef0f2",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#bad4dd",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          border: emailError
                            ? "1px solid #E80000"
                            : "1px solid #bad4dd",
                          boxShadow: emailError
                            ? "0px 0px 0px 2px rgba(232, 0, 0, 0.25)"
                            : "0 0 0 2px rgba(186, 212, 221, 0.25)",
                        },
                      },
                    }}
                  />
                  {emailError && (
                    <FormHelperText
                      sx={{
                        color: "red",
                        fontSize: "14px",
                      }}
                    >
                      <FormattedMessage id="app.errorMessage.pleaseEnterValidInformation"
                        values={{ fieldName: intl.formatMessage({ id: 'app.careInformation.elderInfo.email' }) }} />
                    </FormHelperText>
                  )}
                </div>
              ) : isLoading ? (
                <Skeleton
                  variant="text"
                  width="60%"
                  height={44}
                  sx={{ mt: -1.5 }}
                />
              ) : (
                <Typography sx={elderInfoData}>
                  {formData.email || "-"}
                </Typography>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} sx={{ display: "flex", alignItems: "flex-start" }}>
            <Typography sx={elderInfoTitle}><FormattedMessage id="app.careInformation.elderInfo.emergencyContact" /></Typography>

            <Box sx={{ ml: 4, flexGrow: 1 }}>
              {isEditingElder ? (
                <div style={{ marginTop: "-10px" }}>
                  {Array.isArray(formData.contacts) &&
                    formData.contacts.map((contact, index) => (
                      <Box
                        key={index}
                        sx={{
                          alignItems: "center",
                        }}
                      >
                        <TextField
                          value={contact.name}
                          placeholder="王小姐"
                          onChange={(e) => handleInputChange(e, "name", index)}
                          variant="outlined"
                          size="small"
                          sx={{
                            width: "84px",
                            marginRight: "10px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              height: "44px",
                              "& fieldset": {
                                borderColor: "#eef0f2",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#bad4dd",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: "1px solid #bad4dd",
                                boxShadow:
                                  "0 0 0 2px rgba(186, 212, 221, 0.25)",
                              },
                            },
                          }}
                        />
                        <TextField
                          value={contact.phone}
                          onChange={(e) => handleInputChange(e, "phone", index)}
                          placeholder="09xxxxxxxx"
                          variant="outlined"
                          size="small"
                          error={emergencyContactPhoneError}
                          sx={{
                            width: "190px",
                            "& .MuiOutlinedInput-root": {
                              borderRadius: "10px",
                              height: "44px",
                              backgroundColor: emergencyContactPhoneError
                                ? "#fff4f4"
                                : "transparent",

                              "& fieldset": {
                                borderColor: "#eef0f2",
                              },
                              "&:hover .MuiOutlinedInput-notchedOutline": {
                                borderColor: "#bad4dd",
                              },
                              "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                              {
                                border: emergencyContactPhoneError
                                  ? "1px solid #E80000"
                                  : "1px solid #bad4dd",

                                boxShadow: emergencyContactPhoneError
                                  ? "0px 0px 0px 2px rgba(232, 0, 0, 0.25)"
                                  : "0 0 0 2px rgba(186, 212, 221, 0.25)",
                              },
                            },
                          }}
                        />
                        {emergencyContactPhoneError && (
                          <FormHelperText
                            sx={{
                              color: "red",
                              marginTop: "-1px",
                              fontSize: "14px",
                            }}
                          >
                            <FormattedMessage id="app.errorMessage.pleaseEnterValidInformation"
                              values={{ fieldName: intl.formatMessage({ id: 'app.careInformation.elderInfo.phoneNumber' }) }} />
                          </FormHelperText>
                        )}
                      </Box>
                    ))}
                </div>
              ) : isLoading ? (
                <Skeleton
                  variant="text"
                  width="60%"
                  height={44}
                  sx={{ mt: -1.5 }}
                />
              ) : (
                <Typography sx={elderInfoData}>
                  {formData &&
                    Array.isArray(formData.contacts) &&
                    formData.contacts.map((contact, index) => (
                      <div key={index}>
                        {contact.name || contact.phone
                          ? `${contact.name} / ${contact.phone}`
                          : "-"}
                      </div>
                    ))}
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>

        {isEditingElder ? (
          <>
            <Button
              variant="outlined"
              onClick={handleCancel}
              style={{
                position: "absolute",
                right: 80,
                top: -10,
                width: "60px",
                height: "40px",
                borderRadius: "10px",
                padding: "8px 16px",
                border: "1px solid #0087a9",
                color: "#0087a9",
                fontWeight: 600,
              }}
            >
              <FormattedMessage id="app.common.cancel" />
            </Button>
            <Button
              variant="outlined"
              onClick={handleSave}
              disabled={hasError}
              style={{
                position: "absolute",
                right: 0,
                top: -10,
                width: "64px",
                height: "40px",
                borderRadius: "10px",
                padding: "8px 16px",
                border: "1px solid #0087a9",
                color: "#ffffff",
                backgroundColor: "#0087a9",
                fontWeight: 600,
              }}
            >
              <FormattedMessage id="app.common.save" />
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            startIcon={<InputEditIcon />}
            onClick={handleEditClickElder}
            disabled={isEditingCare || isLoading}
            style={{
              position: "absolute",
              right: 0,
              top: -10,
              width: "92px",
              height: "40px",
              borderRadius: "10px",
              padding: "8px 16px",
              border: "1px solid #424962",
              color: "#424962",
              fontWeight: 600,
            }}
          >
            <FormattedMessage id="app.common.edit" />
          </Button>
        )}
      </Box>
    </>
  );
};

export default ElderInfo;
