import React, { useState, useEffect } from 'react';
import summaryStyle from '../summary.module.scss';
import { cloneDeep, isNil } from 'lodash';
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../hook/AuthContext";
import { FormattedMessage } from "react-intl";
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import { Chart as ChartJS } from 'chart.js/auto';
import { Chart } from 'react-chartjs-2';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

import upper from 'assets/img/icon/btn_arrow_dropdown_upper.svg';
import down from 'assets/img/icon/btn_arrow_dropdown_down.svg';

import {
  defaultChartData,
  defaultChartOptions,
  defaultChartPlugin
} from '../const';
import controller from '../controller';
import { ChartSummaryData } from '../simuData';
// import AuthOrganizaionService from 'services/Auth.Organization.service';

dayjs.extend(utc);
dayjs.extend(timezone);

const arrowIcon = (props) => (
  <img
    src={down}
    alt="down"
    style={{
      width: 20,
      pointerEvents: 'none'
    }}
    {...props}
  />
);

const localStorageId = 'SUMMARY_CHART';
const ChartSummary = (props) => {
  const { wsData, setWsData, card, openSpinner, setOpenSpinner } = props;
  const ws = useWebSocket();
  const { token } = useAuth();

  const [timeRange, setTimeRange] = useState(7);
  const handleRangeChange = (event) => {
    setTimeRange(event.target.value);
    // call DB to get Data
    getData(event.target.value);
  };

  const [chartData, setChartData] = useState(cloneDeep(defaultChartData));
  const [chartOptions, setChartOptions] = useState(cloneDeep(defaultChartOptions));
  const [chartPlugin, setChartPlugin] = useState(cloneDeep(defaultChartPlugin));

  function initChart(range) {
    let result = [];
    let now = controller.getDateYMD(dayjs());
    let dataObj = {
      id: 0,
      year: dayjs(now).format('YYYY'),
      month: dayjs(now).format('MM'),
      hour: '0', //dayjs(now).format('HH'),
      SBP: null,
      DBP: null,
      PULSE: null
      // numberOfDay: 0,
      // numberOfMonth: 0
    };

    if (range === 365) {
      dataObj.day = '01';
      let firstDayOfMonth = dataObj.year + '-' + dataObj.month + '-01';
      dataObj.week = dayjs(firstDayOfMonth).format('ddd');
      dataObj.dateStamp = parseInt(dayjs(firstDayOfMonth).format('X'));
      dataObj.label = dayjs(firstDayOfMonth).format('MMM');
      result.push(dataObj);

      for (let i = 1; i < 12; i++) {
        let prevObj = cloneDeep(result[0]);
        let preTime = prevObj.dateStamp - 1;
        prevObj.id = i;
        prevObj.year = dayjs(preTime * 1000).format('YYYY');
        prevObj.month = dayjs(preTime * 1000).format('MM');
        let firstDayOfPreMonth = prevObj.year + '-' + prevObj.month + '-' + prevObj.day;
        prevObj.week = dayjs(firstDayOfPreMonth).format('ddd');
        prevObj.dateStamp = parseInt(dayjs(firstDayOfPreMonth).format('X'));
        prevObj.label = dayjs(firstDayOfPreMonth).format('MMM');

        result.unshift(prevObj);
      }
    } else {
      dataObj.day = dayjs(now).format('DD');
      dataObj.week = dayjs(now).format('ddd');
      dataObj.dateStamp = parseInt(dayjs(now).format('X'));
      dataObj.label = dataObj.month + '/' + dataObj.day;
      //dayjs(now).format('MM')+'/'+dayjs(now).format('DD')+' '+dayjs(now).format('ddd')
      result.push(dataObj);

      for (let i = 1; i < range; i++) {
        let prevObj = cloneDeep(result[0]);
        let preTime = prevObj.dateStamp - 86400;
        prevObj.id = i;
        prevObj.dateStamp = preTime;
        prevObj.year = dayjs(preTime * 1000).format('YYYY');
        prevObj.month = dayjs(preTime * 1000).format('MM');
        prevObj.day = dayjs(preTime * 1000).format('DD');
        prevObj.week = dayjs(preTime * 1000).format('ddd');
        prevObj.label = prevObj.month + '/' + prevObj.day;

        result.unshift(prevObj);
      }
    }
    return result;
  }

  function generateChart(data, chart, range) {
    let updateData = cloneDeep(chartData);

    chart.forEach((subchart) => {
      data.forEach((subdata, index) => {
        let wsYear = dayjs(subdata.timestamp * 1000).format('YYYY');
        let wsMonth = dayjs(subdata.timestamp * 1000).format('MM');
        let wsDay = dayjs(subdata.timestamp * 1000).format('DD');
        if (range === 365) {
          if (subchart.month === wsMonth && subchart.year === wsYear) {
            subchart.SBP = Math.round(subdata.systolic); //subdata.SBP;
            subchart.DBP = Math.round(subdata.diastolic); //subdata.DBP;
            subchart.PULSE = Math.round(subdata.heartRate); //subdata.PULSE;
          }
        } else {
          if (subchart.month === wsMonth && subchart.day === wsDay) {
            subchart.SBP = Math.round(subdata.systolic);
            subchart.DBP = Math.round(subdata.diastolic);
            subchart.PULSE = Math.round(subdata.heartRate);
            // if(subdata.numberOfDay)
            // subchart.numberOfDay = subdata.numberOfDay;
            // subchart.numberOfMonth = subdata.numberOfMonth;
          }
        }
      });
    });

    updateData.labels = chart.map((item) => item.label);
    updateData.datasets[0].data = chart.map((item) => item.SBP);
    updateData.datasets[1].data = chart.map((item) => item.DBP);
    updateData.datasets[2].data = chart.map((item) => item.PULSE);
    setChartData(updateData);
    setOpenSpinner(false);
    // save data to local storage
    // localStorage.setItem(localStorageId, JSON.stringify(updateData));
  }

  const addNewWsData = async (data) => {
    let updateData = cloneDeep(chartData);
    let latest = updateData.datasets[0].data.length -1;
    let count = (timeRange === 365)? data.numberOfMonth: data.numberOfDay;
    let avgSBP = updateData.datasets[0].data[latest];
    let avgDBP = updateData.datasets[1].data[latest];
    let avgPULSE = updateData.datasets[2].data[latest];

    if(avgSBP === null || avgSBP === ''){ avgSBP = 0; }
    let tmpSBP = (avgSBP * (count-1)+ data.systolic)/count;

    if(avgDBP === null || avgDBP === ''){ avgDBP = 0; }
    let tmpDBP = (avgDBP * (count-1)+ data.diastolic)/count;

    if(avgPULSE === null || avgPULSE === ''){ avgPULSE = 0; }
    let tmpPULSE = (avgPULSE * (count-1)+ data.heartRate)/count;

    updateData.datasets[0].data[latest] = Math.round(tmpSBP);
    updateData.datasets[1].data[latest] = Math.round(tmpDBP);
    updateData.datasets[2].data[latest] = Math.round(tmpPULSE);
    setChartData(updateData);
    setOpenSpinner(false);
    // save data to local storage
    // localStorage.setItem(localStorageId, JSON.stringify(updateData));
  };

  const getData = async (range = 7) => {
    setOpenSpinner(true);
    let endStamp = parseInt(dayjs().format('X'));
    let startStamp = endStamp - 86400 * 7;
    //parseInt(dayjs().subtract(8, 'day').format('X'))

    let averageRange = 'weeklyAverage';
    if (range === 365) {
      averageRange = 'yearlyAverage';
      startStamp = endStamp - 86400 * 365;
    } else if (range === 30) {
      averageRange = 'monthlyAverage';
      startStamp = endStamp - 86400 * 31;
    }
    const chartSendData = {
      resource: `/users/${card[0].userId}/reports`,
      verb: 'read',
      accessToken: token,
      data: {
        filter: {
          startTime: startStamp,
          endTime: endStamp,
          homeGroupId: card[0].homeId,
        },
        projection: [averageRange]
      }
    };

    ws.send(JSON.stringify(chartSendData));
  };

  useEffect(() => {
    if (wsData.updateTag === 'report_creat' && wsData.updateData) {
      console.log('chart summary get creat ws');
      console.log(wsData.updateData);
      addNewWsData(cloneDeep(wsData.updateData));
    } else if (wsData.updateTag === 'report_read' && wsData.updateData) {

      let tmpData = cloneDeep(wsData.updateData);
      let emptyData = [], sortData = [];
      if (tmpData.weeklyAverage === null && tmpData.monthlyAverage === null && tmpData.yearlyAverage === null){
        if(tmpData.reports === null){
          formatData(emptyData);
        }
      }else{
        console.log('chart summary get read ws');
        console.log(wsData.updateData);

        if (timeRange === 7 && tmpData.weeklyAverage !== null) {
          sortData = tmpData.weeklyAverage.sort(sortTime);
        } else if (timeRange === 30 && tmpData.monthlyAverage !== null) {
          sortData = tmpData.monthlyAverage.sort(sortTime);
        } else if (timeRange === 365 && tmpData.yearlyAverage !== null) {
          sortData = tmpData.yearlyAverage.sort(sortTime);
        }

        if (sortData.length > 0) {
          formatData(sortData);
        }
      }
    }
  }, [wsData]);

  const formatData = async (sortData) => {
    let defaultData = cloneDeep(sortData);
    let defaultChart = initChart(timeRange);
    // console.log(defaultData)
    // console.log(defaultChart)
    generateChart(defaultData, defaultChart, timeRange);
  };

  function sortTime(a, b) {
    return a.timestamp - b.timestamp;
  }

  useEffect(() => {
    // let tmpData = localStorage.getItem(localStorageId)
    // if(chartData.labels.length === 0 && tmpData !== null){
    //   setChartData(JSON.parse(tmpData));
    // }
  }, [chartData]);

  return (
    <>
      <Card
        variant="outlined"
        style={{
          width: '100%',
          height: '350px',
          marginTop: '5px',
          borderRadius: '10px'
        }}
      >
        <Box style={{ padding: '20px 15px', width: 'auto', height: '30px' }}>
          <Grid container justifyContent="space-between">
            <Typography
              variant="h6"
              component="div"
              color="#424962"
              style={{ fontWeight: 'bold' }}
            >
              <FormattedMessage id="app.summary.bloodPressureAndHeartRate" />
            </Typography>
            <FormControl style={{ minWidth: 180 }}>
              <Select
                style={{ height: '40px' }}
                value={timeRange}
                onChange={handleRangeChange}
                IconComponent={arrowIcon}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  // sx: {
                  //   '&& .Mui-selected': { background: '#EEF0F2' },
                  //   '&& .MuiMenuItem-root': { color: '#737791' }
                  // }
                  sx: {
                    "&& .Mui-selected": {
                      backgroundColor: "#ebf5ff",
                    },
                    "&& .MuiMenuItem-root": {
                      color: "#737791",
                      "&:hover": {
                        backgroundColor: "#eef0f2",
                      },
                    },
                    "&& .MuiMenuItem-root:hover": {
                      backgroundColor: "#eef0f2",
                    },
                  },
                  PaperProps: {
                    sx: {
                      width: "150px",
                      borderRadius: "10px",
                      boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.08)",
                      "& .MuiMenuItem-root:first-of-type": {
                        marginTop: "-8px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                      },
                      "& .MuiMenuItem-root:last-of-type": {
                        marginBottom: "-8px",
                        borderBottomLeftRadius: "10px",
                        borderBottomRightRadius: "10px",
                      },
                    },
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "right",
                  },
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "right",
                  },
                }}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    // borderColor: '#BEBEBE',
                    borderRadius: "12px",
                    borderColor: '#eef0f2'
                  },
                  '& .MuiSelect-select': { color: '#737791' },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bad4dd'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: '#bad4dd'
                  },
                }}
              >
                <MenuItem color={'#737791'} value={7}>
                  <FormattedMessage id="app.common.inThePastFewDays" values={{ days: '7' }} />
                </MenuItem>
                {/* <MenuItem value={14}>last 14 days</MenuItem> */}
                <MenuItem value={30}><FormattedMessage id="app.common.inThePastFewDays" values={{ days: '30' }} /></MenuItem>
                <MenuItem value={365}><FormattedMessage id="app.common.lastYear" /></MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Box>
        <Box style={{ padding: '10px 15px', width: 'auto', height: '220px' }}>
          <Chart
            type="line"
            data={chartData}
            options={chartOptions}
            plugins={[chartPlugin]}
          ></Chart>
        </Box>
        <Grid container alignItems="center" justifyContent="center" spacing={0}>
          <div
            style={{
              marginLeft: '0px',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: '#d3adf7'
            }}
          ></div>
          <span style={{ marginLeft: '5px', color: '#6f748f' }}><FormattedMessage id="app.common.systolicBloodPressure" /></span>
          <div
            style={{
              marginLeft: '40px',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: '#24c76f'
            }}
          ></div>
          <span style={{ marginLeft: '5px', color: '#6f748f' }}><FormattedMessage id="app.common.diastolicBloodPressure" /></span>
          <div
            style={{
              marginLeft: '40px',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: '#e23939'
            }}
          ></div>
          <span style={{ marginLeft: '5px', color: '#6f748f' }}><FormattedMessage id="app.common.heartRate" /></span>
        </Grid>
        {/* </Box> */}
      </Card>
    </>
  );
};

export default ChartSummary;
