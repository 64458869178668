import React, { useState, useEffect } from 'react';

import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';

import FieldContainer from '../FieldContainer';

import { ReactComponent as SelectDropdownDownBtn } from 'assets/img/icon/btn_arrow_dropdown_down.svg';
import { ReactComponent as SelectDropdownUpperBtn } from 'assets/img/icon/btn_arrow_dropdown_upper.svg';

const SelectField = ({ formikProps, proportion, id, label, labelColor, placeholder, options, multiple = false, disabled = false }) => {
  const { errors, touched, getFieldProps, setFieldValue } = formikProps;
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // 檢查是否沒有 placeholder 且值尚未設定，且 options 有資料
    if (!placeholder && options.length > 0) {
      const value = getFieldProps(id).value;

      // 若非多選，則將第一筆資料設為預設選項
      if (!multiple && !value) {
        setFieldValue(id, options[0].value);
      }

      // 若是多選，且值為空陣列，則將第一筆資料設為預設選項
      if (multiple && value.length === 0) {
        setFieldValue(id, [options[0].value]);
      }
    }
  }, [id, setFieldValue, getFieldProps, options, multiple]);


  return (
    <Stack spacing={1}>
      <FieldContainer proportion={proportion} label={label} labelColor={labelColor}>
        <FormControl
          fullWidth
          error={Boolean(touched[id] && errors[id])}
        >
          <Select
            MenuProps={{
              sx: {
                "&& .Mui-selected": {
                  backgroundColor: "#ebf5ff",
                },
                "&& .MuiMenuItem-root": {
                  color: "#737791",
                  "&:hover": {
                    backgroundColor: "#eef0f2",
                  },
                },
                "&& .MuiMenuItem-root:hover": {
                  backgroundColor: "#eef0f2",
                },
              },
              PaperProps: {
                sx: {
                  width: "200px",
                  borderRadius: "10px",
                  boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.08)",
                  "& .MuiMenuItem-root:first-of-type": {
                    marginTop: "-8px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                  },
                  "& .MuiMenuItem-root:last-of-type": {
                    marginBottom: "-8px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  },
                },
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "right",
              },
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "right",
              },
            }}
            sx={{
              "& .MuiSelect-select": { color: "#737791" },
              "& .MuiSelect-icon": {
                top: "50%",
                transform: "translateY(-50%)",
              },
              "&.MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "#eef0f2",
                  margin: "5px 0px",
                },
                "&:hover fieldset": { borderColor: "#bad4dd" },
                "&.Mui-focused fieldset": {
                  borderColor: "#bad4dd",
                  boxShadow: "0 0 0 2px rgba(186, 212, 221, 0.25)"
                },
              },
              "& .MuiOutlinedInput-notchedOutline": {
                borderRadius: "12px",
              },
            }}
            id={id}
            multiple={multiple}
            displayEmpty
            IconComponent={open ? SelectDropdownUpperBtn : SelectDropdownDownBtn}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            {...getFieldProps(id)}
            inputProps={{ 'aria-label': label }}
            renderValue={(selected) => {
              if (multiple) {
                if (!Array.isArray(selected)) {
                  return placeholder;
                }
                // 顯示所有選中的 label，並以逗號分隔
                return selected.length ? selected.map((val) => {
                  const option = options.find(option => option.value === val);
                  return option ? option.label : '';
                }).join(', ') : placeholder;
              } else {
                // 單選模式，顯示選中的 label
                const selectedOption = options.find(option => option.value === selected);
                return selectedOption ? selectedOption.label : placeholder;
              }
            }}
            disabled={disabled}
          >
            <MenuItem disabled value="">
              {placeholder}
            </MenuItem>
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          {touched[id] && errors[id] && (
            <FormHelperText>{errors[id]}</FormHelperText>
          )}
        </FormControl>
      </FieldContainer>
    </Stack>
  );
};

export default SelectField;