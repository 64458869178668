import { FormattedMessage } from "react-intl";

//** Required */
export const PATH = {
  // login: {
  //   main: "/login",
  //   name: "Login",
  // },
  dashboard: {
    main: "/dashboard",
    name: <FormattedMessage id="app.common.functionList.dashboard" />,
  },
  summary: {
    main: "/summary",
    name: <FormattedMessage id="app.common.functionList.summary" />,
  },
  recipient_setting: {
    main: "/recipient_setting",
    name: <FormattedMessage id="app.common.functionList.recipientSetting" />,
  },
  devices: {
    main: "/devices",
    name: <FormattedMessage id="app.common.functionList.devices" />,
  },
  calendar: {
    main: "/calendar",
    name: <FormattedMessage id="app.common.functionList.calendar" />,
  },
  event: {
    main: "/event",
    name: <FormattedMessage id="app.common.functionList.event" />,
  },
  user_setting: {
    main: "/user_setting",
    name: "User Setting",
  },
  add_recipient: {
    main: "/add_recipient",
    name: "add Recipient",
  },
  add_group: {
    main: "/add_group",
    name: "add HomeGroup",
  },
  IoTDashboard: {
    main: "/IoTDashboard",
    name: "old Dashboard",
  },
  question: {
    main: "/question",
    name: <FormattedMessage id="app.common.functionList.question" />,
  },
  policy: {
    main: "/policy",
    name: <FormattedMessage id="app.common.functionList.policy" />,
  }
};
//** Other */
export const OPEN_ALL = {
  enableOrgDp: true,
  enableSiteDp: true,
};

export const FORBID_ALL = {
  enableOrgDp: false,
  enableSiteDp: false,
};