import { FormattedMessage } from "react-intl";

export const CHINESE_MONTHS = [
  <FormattedMessage id="app.common.january" />,
  <FormattedMessage id="app.common.february" />,
  <FormattedMessage id="app.common.march" />,
  <FormattedMessage id="app.common.april" />,
  <FormattedMessage id="app.common.may" />,
  <FormattedMessage id="app.common.june" />,
  <FormattedMessage id="app.common.july" />,
  <FormattedMessage id="app.common.august" />,
  <FormattedMessage id="app.common.september" />,
  <FormattedMessage id="app.common.october" />,
  <FormattedMessage id="app.common.november" />,
  <FormattedMessage id="app.common.december" />,
];

export const WEEK_DAYS = [
  <FormattedMessage id="app.common.saturday" />,
  <FormattedMessage id="app.common.monday" />,
  <FormattedMessage id="app.common.tuesday" />,
  <FormattedMessage id="app.common.wednesday" />,
  <FormattedMessage id="app.common.thursday" />,
  <FormattedMessage id="app.common.friday" />,
  <FormattedMessage id="app.common.sunday" />,
];

export const TYPE_OPTIONS = {
  ['outside']: <FormattedMessage id="app.common.outside" />,
  ['takeBloodPressure']: <FormattedMessage id="app.common.physiologicalMeasurements" />,
  ['takeMedicine']: <FormattedMessage id="app.common.takeMedicine" />,
  ['hospital']: <FormattedMessage id="app.common.hospital" />,
  ['other']: <FormattedMessage id="app.common.other" />,
}
