import { CompareSharp } from '@mui/icons-material';
import dayjs from 'dayjs';

export const defaultChartData = {
  labels: [],//['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      label: "收縮壓",
      data: [],//[120, 115, 110, 130, 125, 135, 120],
      backgroundColor: [
        "#d3adf7",
      ],
      borderColor: "#d3adf7",
      borderWidth: 3,
      tension: 0.3,
      pointRadius: 0.3
    },
    {
      label: "舒張壓",
      data: [],//[80, 70, 65, 80, 75, 85, 75],
      backgroundColor: [
        "#24c76f",
      ],
      borderColor: "#24c76f",
      borderWidth: 3,
      tension: 0.3,
      pointRadius: 0.3
    },
    {
      label: "心率",
      data: [],//[90, 85, 80, 87, 81, 99, 88],
      backgroundColor: [
        "#e23939",
      ],
      borderColor: "#e23939",
      borderWidth: 3,
      tension: 0.3,
      pointRadius: 0.3
    },
  ]
};

export const defaultChartOptions = {
  responsive: true,
  maintainAspectRatio: false,
  elements: {
    point:{ radius: 0 }
  },
  scales: {
    x: {
      grid:{ display:false },
      beforeFit: function(axis) {
        var totalLabel=axis.getLabels();
        if(totalLabel.length > 15){
          let newTick = []
          totalLabel.forEach((tick, index) => {
            if(index%2 !==0){
              newTick.push({ value: index, label:totalLabel[index] })
            }
          })
          axis.ticks = newTick;
        }
        // axis.ticks.push({ value:axis.max, label:l[axis.max] }); 
      },
      ticks: {
        font: {
          size: 14
        },
        // autoSkip: true,
        // maxRotation: 0,
        // minRotation: 0,
        maxTicksLimit: 15
      }
    },
    y: {
      grid:{ },
      ticks: {
        font: {
          size: 14
        }
      },
    },
  },
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    legend: { display: false },
    title: { display: false },
    hover: {
      mode: 'index',
      intersect: false
    },
    tooltip:{
      mode: 'index',
      intersect: false,
      borderWidth: 1,
      borderColor: '#339FBA',
      backgroundColor: 'rgba(0,135,168,0.8)',
      // yAlign: 'bottom',
      // xAlign: 'center',
      caretSize: 5,
      titleAlign: 'center',
      titleColor: '#FFFFFF',
      titleMarginBottom: 8,
      bodySpacing: 6,
      bodyColor: '#9D9D9D',
      titleFont: { size: 14, weight: 'bold' },
      bodyFont: { size: 14, weight: 'bold' },
      displayColors: false,
      padding: 10,
      callbacks: {
        title: function(tooltipItems){
          if(tooltipItems[0].label.indexOf('/') > 0){
            let year = dayjs().format('YYYY')
            let month = tooltipItems[0].label.split('/')[0]
            let day = tooltipItems[0].label.split('/')[1]
            let week = dayjs(year+'-'+month+'-'+day).format('ddd')
            return year+' / '+ month+' / '+day; //tooltipItems[0].label+ '   '+week;
          }
        },
        label: function (tooltipItems) {
          return  " "+ tooltipItems.dataset.label + " : "+ tooltipItems.formattedValue;
        },
        labelTextColor: (tooltipItems) => {
          return "#FFFFFF";
        }
      }
    },
    annotation: {
      annotations: { }
    }
  },
};

export const defaultChartPlugin = {
  id: 'hoverLine',
  beforeDatasetsDraw: function(chart, args, plugins){
    const { ctx, tooltip, scales: {x,y},
      chartArea: { top, bottom, left, right, width, height}} = chart;
    if(tooltip._active.length > 0){
      const activePoint = chart.tooltip._active[0];
      const x = activePoint.element.x;
      const topY = chart.scales.y.top;
      const bottomY = chart.scales.y.bottom;
      ctx.save();
      ctx.beginPath();
      ctx.moveTo(x, topY);
      ctx.lineTo(x, bottomY);
      ctx.lineWidth = 2;
      ctx.strokeStyle = '#0A8AAB';
      ctx.setLineDash([6,6]);
      ctx.stroke();
      ctx.restore();
    }
  },
};