import React from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ReactComponent as Calendar } from "assets/img/icon/icn_menu_schedule.svg";
import dayjs from "dayjs";

const grayColor = "eef0f2";

const CustomDatePicker = ({ value, onChange, error, maxDate,minDate  }) => {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={["DatePicker"]}>
        <DatePicker
          value={dayjs(value)}
          onChange={onChange}
          format="YYYY / MM / DD"
          maxDate={maxDate}
          minDate={minDate}
          slots={{
            openPickerIcon: Calendar,
          }}
          slotProps={{
            day: {
              sx: {
                "&.MuiPickersDay-root.Mui-selected": {
                  backgroundColor: "#0A87A9",
                },
              },
            },
          }}
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: "10px",
              height: "44px",
              overflow: "hidden",
              backgroundColor: error ? "#fff4f4" : "transparent",
              "& fieldset": {
                borderColor: "#eef0f2",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#bad4dd",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: error ? "1px solid #E80000" : "1px solid #bad4dd",
                boxShadow: error
                  ? "0px 0px 0px 2px rgba(232, 0, 0, 0.25)"
                  : "0 0 0 2px rgba(186, 212, 221, 0.25)",
              },
            },
            width: "284px",
          }}
        />

      </DemoContainer>
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
