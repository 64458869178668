import summaryStyle from './summary.module.scss';
import { panelTheme } from './style';
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";
import { cloneDeep, isNil } from 'lodash';
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { PATH } from "router/config/const";
import Breadcrumb from 'components/Breadcrumb';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Divider from "@mui/material/Divider";
import TabList from '@mui/lab/TabList';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Typography from '@mui/material/Typography';
import Spinner from 'components/Spinner';
import setting from "assets/img/icon/icn_profile_setting.svg";
import event from "assets/img/icon/icn_profile_event.svg";
import ChartSummary from "./components/ChartSummary";
import TimeLine from "./components/TimeLine";
import History from "./components/History";
import UserCard from "./components/UserCard";
// import Recipient from "./components/RecipientSidebar";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNotification } from "hook/NotificationContext";
import controller from './controller';
// Controller
// import controller from './controller';


// const testData = [
//   { id: 0, identity: '爸爸', age: '65', gender:'男', content: 'Recipient card 1', note: true, noteSrc: errorNote, showSummary: true, photoSrc: userPhoto1 },
// ]

const Summary = (props) => {
  const { data } = props;
  const [wsData, setWsData] = useOutletContext();
  const ws = useWebSocket();
  const { token } = useAuth();

  const userInfo = useSelector((state) => state.user.userInfo);
  // const [openModal, setOpenModal] = useState(false);
  // const [user, setUser] = useState({});

  const [card, setCard] = useState([]);
  
  const [tabValue, setTabValue] = useState('1');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1280));
  const isLargeScreen = useMediaQuery(theme.breakpoints.down(1824));

  const [openSpinner, setOpenSpinner] = useState(false);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getMemberData = async (user) => {
    const groupSendData = {
      resource: `/homegroups/${user.lastUsedHomegroupId}/members/Elder`,
      verb: 'read',
      accessToken: token,
    };
    ws.send(JSON.stringify(groupSendData));
  };


  const getUserCardData = async (member) => {
    const startTime = dayjs().subtract(24, "hour").unix();
    const endTime = dayjs().unix();
    const reportSendData = {
      resource: `/users/${member.userId}/reports`,
      verb: 'read',
      accessToken: token,
      data: {
        filter: {
          homeGroupId: member.homeId,
          startTime: startTime,
          endTime: endTime,
        },
        projection: ["checkAbnormalities"],
      }
    };
    ws.send(JSON.stringify(reportSendData));
  }


  const getChartData = async (card) => {
    let endStamp = parseInt(dayjs().format('X'));
    let tmp_stampRange = endStamp - 86400*7;
    let tmp_startDate = controller.getDateYMD(tmp_stampRange*1000)
    let startStamp = controller.getDate0000(tmp_startDate)
    let averageRange = ['weeklyAverage','reports'];

    const chartSendData = {
      resource: `/users/${card.userId}/reports`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: startStamp,
          endTime: endStamp,
          homeGroupId: card.homeId, //4
        },
        projection: averageRange
      },
    };

    ws.send(JSON.stringify(chartSendData));
    setOpenSpinner(false);
  }

  useEffect(() => {
    if(Object.keys(userInfo).length !== 0){
      // setUser(userInfo)
      // call home group data
      getMemberData(userInfo)
      setOpenSpinner(true);
      // return () => {
      //   localStorage.removeItem('SUMMARY_CHART');
      //   localStorage.removeItem('SUMMARY_DETAIL');
      // };
    }else{
      // return () => {
      //   localStorage.removeItem('SUMMARY_CHART');
      //   localStorage.removeItem('SUMMARY_DETAIL');
      // };
    }
    
  }, [userInfo]);

  useEffect(() => {
    if (wsData.updateTag === 'homegroups_read' && wsData.updateData) {
      console.log('summary homegroup get ws');
      console.log(wsData.updateData);
      // get home group data
      // call user report data
      let memberAry = []
      let members = cloneDeep(wsData.updateData.members)
      if (members && members.length > 0) {
        members.forEach((member) => {
          let card = { 
            userId: member.userId, 
            homeId: wsData.updateData.homeGroupId,
            name: member.name,
            photo: member.portrait,
            role: member.role,
            age: '65', 
            gender:'男',
            selected: false,
            note: false,
            status: {
              total: 'noData',
              pressure: 'noData',
              medicine: 'noData',
              heart: 'noData',
              behavior: 'noData',
            }
          }
          memberAry.push(card)
        });
        setCard(memberAry)
        getUserCardData(memberAry[0]);
      }
    }else if (wsData.updateTag === 'report_read' && wsData.updateData) {
      let tmpData = cloneDeep(wsData.updateData);
      if (tmpData.reports === null && tmpData.weeklyAverage === null && tmpData.monthlyAverage === null && tmpData.yearlyAverage === null){
        console.log('summary user report get ws');
        console.log(wsData.updateData);
        let tmpCard = cloneDeep(card);
        let tmpReport = cloneDeep(tmpData.abnormalReports);
        // get user report data
        // call chart data
        if(tmpReport && tmpCard.length > 0){
          tmpCard[0].selected = true
          if(tmpReport.diastolic === 'normal' && tmpReport.systolic === 'normal'){
            tmpCard[0].status.pressure = 'normal'
          }else if(tmpReport.diastolic === 'noData' && tmpReport.systolic === 'noData'){
            tmpCard[0].status.pressure = 'noData'
          }else{
            tmpCard[0].status.pressure = 'abnormal'
          }
          if(tmpReport.heartRate === 'normal'){
            tmpCard[0].status.heart = 'normal'
          }else if(tmpReport.diastolic === 'noData'){
            tmpCard[0].status.heart = 'noData'
          }else{
            tmpCard[0].status.heart = 'abnormal'
          }
          if(tmpCard[0].status.pressure === 'abnormal' || tmpCard[0].status.medicine === 'abnormal' || tmpCard[0].status.heart === 'abnormal' || tmpCard[0].status.behavior === 'abnormal'){
            tmpCard[0].status.total = 'abnormal'
            tmpCard[0].note = true
          }else if(tmpCard[0].status.pressure === 'noData' && tmpCard[0].status.medicine === 'noData' && tmpCard[0].status.heart === 'noData' && tmpCard[0].status.behavior === 'noData'){
            tmpCard[0].status.total = 'noData'
          }else{
            tmpCard[0].status.total = 'normal'
          }
          setCard(tmpCard) 
          getChartData(card[0])
        }
      } 
    }
  }, [wsData]);


  return (
    <>
      <Breadcrumb path={PATH.summary}></Breadcrumb>
      <Box sx={{ display: 'flex', width: '100%'}}>
      <Grid container style={{paddingTop: '10px', borderRadius: '10px'}}>
        <Grid item style={{width: (isSmallScreen?'100%':'300px')}}>
        {/* <Grid item xs={isSmallScreen? 12: isLargeScreen? 3:2}> */}
        {/* <Grid item xs={isSmallScreen? 12:3}> */}
        <Grid container style={{paddingRight: '25px'}}>
          <UserCard card={card} openSpinner={openSpinner} setOpenSpinner={setOpenSpinner}/>
        </Grid>
        </Grid>
        <Grid item style={{width: (isSmallScreen?'100%':'calc(100% - 300px)'), marginBottom: '35px' }}>
        {/* <Grid item xs={isSmallScreen? 12: isLargeScreen? 9:10}> */}
        {/* <Grid item xs={isSmallScreen? 12:9}> */}
          <TabContext value={tabValue}>
            <Box sx={{ p: 0 }} variant="outlined"
              style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
              <Tabs value={tabValue} style={{minWidth:"50%"}}
                onChange={handleChange} aria-label="lab API tabs example"
                TabIndicatorProps={{ sx: { backgroundColor: "white", height: '1px'} }}
                sx={{
                    '& button': {fontSize: "16px", backgroundColor: '#EEF0F2', color: '#737791'},
                    '& button: active': {backgroundColor: '#FFFFFF', color: '#0087A8'},
                    '& button: focus': {backgroundColor: '#FFFFFF', color: '#0087A8'},
                    '& button: blur': {backgroundColor: '#FFFFFF', color: '#0087A8', },
                    '& .MuiButtonBase-root': {
                      '&.Mui-selected': { fontSize: "18px", fontWeight: 600, borderRadius: 1, backgroundColor: '#FFFFFF', color: '#0087A8'},
                    },
                }}
              >
                <Tab label={<FormattedMessage id="app.summary.healthData" />} value="1" style={{textTransform: "none", height:'60px', borderRadius: '20px 20px 0 0', minWidth:"45%"}}/>
                {/* <Tab label="History" value="2" style={{textTransform: "none", height:'60px', borderRadius: '0 20px 0 0', minWidth:"45%"}}/> */}
              </Tabs>
              <Box style={{minWidth:"20%"}}>
                <Grid container justifyContent="end">
                  <div className={summaryStyle['profileIcon']} style={{minWidth: '50px'}}>
                    <img src={event}/>
                  </div>
                  <div className={summaryStyle['profileIcon']} style={{minWidth: '50px'}}>
                    <img src={setting}/>
                  </div>
                </Grid>
              </Box>
            </Box>
            <TabPanel value="1" style={panelTheme}>
              <Box sx={{ p: 1 }} variant="outlined">
                <ChartSummary wsData={wsData} setWsData={setWsData} card={card} 
                 openSpinner={openSpinner}  setOpenSpinner={setOpenSpinner}/>
              </Box>
              <Box sx={{ p: 1 }} variant="outlined" style={{marginTop: '15px'}}>
                <TimeLine wsData={wsData} setWsData={setWsData} card={card} 
                  openSpinner={openSpinner}  setOpenSpinner={setOpenSpinner}/>
              </Box>
            </TabPanel>
            <TabPanel value="2" style={panelTheme}>
              <Box sx={{ p: 1 }} variant="outlined">
                <History wsData={wsData} setWsData={setWsData}/>
              </Box>
            </TabPanel>
          </TabContext>
        </Grid>
      </Grid>
      </Box>
      <Spinner open={openSpinner}/>   
    </>
  );
};

export default Summary;