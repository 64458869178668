export const panelTheme = {
  padding: '20px 20px 10px 20px', 
  position: "relative",
  borderRadius: '0px 20px 20px 20px', 
  backgroundColor:'white'
}

export const tabTheme = {
  // position: "absolute", 
  // zIndex: '1000'
}