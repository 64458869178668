import { FormattedMessage } from "react-intl";

import AppstoreOutlined from '@ant-design/icons/AppstoreOutlined';
import LayoutOutlined from '@ant-design/icons/LayoutOutlined';
import PicCenterOutlined from '@ant-design/icons/PicCenterOutlined';
import OrderedListOutlined from '@ant-design/icons/OrderedListOutlined';

export const CALENDAR_VIEW_OPTIONS = [
  {
    label: <FormattedMessage id="app.common.month" />,
    value: 'dayGridMonth',
    icon: AppstoreOutlined
  },
  {
    label: <FormattedMessage id="app.common.week" />,
    value: 'timeGridWeek',
    icon: LayoutOutlined
  },
  {
    label: <FormattedMessage id="app.common.day" />,
    value: 'timeGridDay',
    icon: PicCenterOutlined
  },
  {
    label: <FormattedMessage id="app.calendar.list" />,
    value: 'listWeek',
    icon: OrderedListOutlined
  }
];

export const CALENDAR_EVENT_COLORS = {
  ['outside']: ['#7ebbd6', '#e9f4fa'],
  ['hospital']: ['#55ccdd', '#e6fffb'],
  ['other']: ['#737791', '#efefef'],
  ['takeBloodPressure']: ['#ff947a', '#fff7e6'],
  ['takeMedicine']: ['#d3adf7', '#f9f0ff'],
}

export const TYPE_OPTIONS = [
  {
    value: 'outside',
    label: <FormattedMessage id="app.common.outside" />,
  },
  {
    value: 'takeBloodPressure',
    label: <FormattedMessage id="app.common.physiologicalMeasurements" />,
  },
  {
    value: 'takeMedicine',
    label: <FormattedMessage id="app.common.takeMedicine" />,
  },
  {
    value: 'hospital',
    label: <FormattedMessage id="app.common.hospital" />,
  },
  {
    value: 'other',
    label: <FormattedMessage id="app.common.other" />,
  }
]

export const EDIT_TYPE_OPTIONS = [
  {
    value: 'outside',
    label: <FormattedMessage id="app.common.outside" />,
  },
  {
    value: 'hospital',
    label: <FormattedMessage id="app.common.hospital" />,
  },
  {
    value: 'other',
    label: <FormattedMessage id="app.common.other" />,
  }
]