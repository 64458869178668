import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    "fontFamily": `"Inter"`,
  }
  ,
  palette: {
    error: {
      main: "#E80000",
    },
  },
});

export default theme;