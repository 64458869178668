import React, { useState, useEffect, useRef } from "react";
import calendarStyle from "./calendar.module.scss";

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../hook/AuthContext";

import { FormattedMessage } from "react-intl";
import dayjs from 'dayjs';
import _, { cloneDeep } from 'lodash';

import { useTheme, useMediaQuery } from "@mui/material";
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import CardContent from "@mui/material/CardContent";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";

import Spinner from 'components/Spinner';
import AvatarGroup from 'components/AvatarGroup';

import { ReactComponent as ArrowBackIcon } from 'assets/img/icon/btn_calendar_arrow_left.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/img/icon/btn_calendar_arrow_right.svg';
import { ReactComponent as ExternalExpandIcon } from 'assets/img/icon/btn_external_expand.svg';

import { CHINESE_MONTHS, WEEK_DAYS, TYPE_OPTIONS } from "./const";
import { CALENDAR_EVENT_COLORS } from "pages/Calendar/const";

import { toUnixTimestamp, formatTimestampToTime } from 'utils/utils';

// Calendar style
const calendarContentStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'stretch',
  width: '100%',
  height: '100%',
  maxWidth: '100%',
  maxHeight: 'none',
  '@media (min-width: 1200px)': {
    height: '52%',
  },
  '@media (min-width: 1200px) and (max-width: 1439px)': {
    height: '95%',
  },
  '& .MuiDayCalendar-slideTransition': {
    minHeight: '300px',
    '@media (max-width: 600px)': {
      minHeight: '240px',
    },
  },
  '.MuiPickersCalendarHeader-root': {
    marginBottom: '16px',
    width: '100%',
  },
  '.MuiDayCalendar-header': {
    display: 'flex',
    justifyContent: 'space-around',
  },
  '& .MuiDayCalendar-weekDayLabel': {
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.43,
    color: '#737791',
    height: '50px',
  },
  '.MuiPickersDay-root': {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    margin: '0 auto',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 1.43,
    '@media (max-width: 600px)': {
      width: '35px',
      height: '35px',
    },
    '@media (min-width: 1200px)': {
      width: '52px',
      height: '52px',
    },
  },
}

const CalendarFeild = ({ setCalendarData, selectedDate, setSelectedDate, setCurrentCalendarViewDateRange, highlightedDays }) => {
  const CustomCalendarHeader = (props) => {
    const { currentMonth, onMonthChange } = props;
    const navigate = useNavigate();

    return (
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <IconButton
            onClick={() => onMonthChange(dayjs(currentMonth).subtract(1, 'month'))}
          >
            <ArrowBackIcon className={calendarStyle.calendarIcon} />
          </IconButton>
          <Typography
            sx={{
              marginRight: '24px', marginLeft: '24px', color: '#737791',
              fontWeight: 600, fontSize: '18px', lineHeight: 1.11, textAlign: 'left'
            }}
          >
            {CHINESE_MONTHS[dayjs(currentMonth).month()]} {dayjs(currentMonth).year()}
          </Typography>
          <IconButton
            onClick={() => onMonthChange(dayjs(currentMonth).add(1, 'month'))}
          >
            <ArrowForwardIcon className={calendarStyle.calendarIcon} />
          </IconButton>
        </Box>
        <Button variant="outlined"
          startIcon={<ExternalExpandIcon className={calendarStyle.calendarIcon} />}
          sx={{
            display: 'flex', gap: '8px', padding: '8px 16px', fontSize: '16px', fontWeight: 600,
            lineHeight: 1.25, color: '#424962', borderRadius: '10px', borderColor: '#424962',
            textTransform: 'none',
          }}
          onClick={() => navigate('/calendar')}
        >
          <FormattedMessage id="app.dashboard.moreInformation" />
        </Button>
      </Box>
    );
  };

  const ServerDay = (props) => {
    const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

    const dayOfMonth = dayjs(day).format('YYYY-MM-DD')
    const today = dayjs().format('YYYY-MM-DD');

    const isToday = dayOfMonth === today;
    const isSelectedToday = dayjs(selectedDate).format('YYYY-MM-DD') === today;
    const isSelectedDate = !outsideCurrentMonth && highlightedDays.includes(dayOfMonth);

    const isHighlightedDate = dayOfMonth === dayjs(selectedDate).format('YYYY-MM-DD');

    return (
      <PickersDay
        {...other}
        day={day}
        outsideCurrentMonth={outsideCurrentMonth}
        sx={{
          "&.MuiPickersDay-root": {
            position: 'relative',
            border: 'none',
            color: outsideCurrentMonth ? '#cccccc' : isToday ? '#0087a9' : '#737791',
            "&::after": {
              content: '""',
              display: isSelectedDate ? 'block' : 'none',
              width: '5px',
              height: '5px',
              backgroundColor: isHighlightedDate ? '#ffffff' : '#737791',
              borderRadius: '50%',
              position: 'absolute',
              bottom: 10,
              left: '50%',
              transform: 'translateX(-50%)',
              '@media (max-width: 600px)': {
                bottom: 4,
              },
            },
          },
          "&.Mui-selected": {
            backgroundColor: isSelectedToday ? '#0087a9 !important' : '#737791 !important',
            color: '#ffffff',
          },
        }}
      />
    );
  }

  const calculateCalendarRange = (date) => {
    setCalendarData([]);
    // 取得當前月份的第一天
    const firstDayOfMonth = dayjs(date).startOf("month");
    // 找到日曆顯示的第一個星期日
    const firstDayOfCalendar = firstDayOfMonth.startOf("week").startOf("day");
    // 找到日曆顯示的最後一個星期六
    const lastDayOfMonth = dayjs(date).endOf("month");
    const lastDayOfCalendar = lastDayOfMonth.endOf("week").endOf("day");;

    setCurrentCalendarViewDateRange([firstDayOfCalendar, lastDayOfCalendar]);
  };

  return (
    <>
      {
        highlightedDays && <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateCalendar
            slots={{
              calendarHeader: CustomCalendarHeader,
              day: ServerDay,
            }}
            slotProps={{
              day: {
                highlightedDays,
              },
            }}
            sx={{ ...calendarContentStyle }}
            showDaysOutsideCurrentMonth
            dayOfWeekFormatter={(date) => (WEEK_DAYS[date.day()])}
            fixedWeekNumber={4}
            value={selectedDate}
            onChange={(newDate) => setSelectedDate(newDate)}
            onMonthChange={(newDate) => calculateCalendarRange(newDate)}
          />
        </LocalizationProvider>
      }
    </>
  );
}

const ScheduleList = ({ isSelectedDate, selectedData }) => (
  <div className={calendarStyle.scheduleListContainer}>
    {
      isSelectedDate && selectedData.map((item) => (
        <div style={{ borderColor: item.eventColors[0] }} key={item.id} className={calendarStyle.scheduleList}>
          <div style={{ backgroundColor: item.eventColors[1] }} className={calendarStyle.scheduleItem}>
            <div className={calendarStyle.scheduleItemActivity}>
              <AvatarGroup avatarContainerMargin="0 10px 0 8px" avatarWidthAndHeight="38px"
                data={item.avatar} length={2} />
              <div className={calendarStyle.info}>
                <div className={calendarStyle.title}>{item.title}</div>
                <div className={calendarStyle.subtitle}>
                  <span>{item.avatar.map((avatarItem, index) =>
                    `${avatarItem.name}${index < item.avatar.length - 1 ? ', ' : ''}`)} ;</span>
                  {TYPE_OPTIONS[item.type]}
                </div>
              </div>
            </div>
            <div className={calendarStyle.scheduleItemTimeLine}>
              {
                item.allDay
                  ?
                  <div className={calendarStyle.allDay}><FormattedMessage id="app.common.allDay" /></div>
                  : <>
                    <div className={calendarStyle.startTime}>{item.start}</div>
                    <div className={calendarStyle.endTime}>{item.end}</div>
                  </>
              }
            </div>
          </div>
        </div>
      )) || <Box sx={{ height: '200px', display: 'flex' }}>
        <Typography sx={{ margin: 'auto', color: '#424962', lineHeight: 1.25, fontSize: '16px', fontWeight: 600 }} >
          <FormattedMessage id="app.dashboard.calendar.noTrip" />
        </Typography>
      </Box>
    }
  </div>
)

const Calendar = (props) => {
  const { wsData, setWsData, fullScreenArrange } = props;
  const ws = useWebSocket();
  const { token } = useAuth();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1440));

  const homeGroupId = useSelector((state) => state.user.userInfo).lastUsedHomegroupId;

  const [openSpinner, setOpenSpinner] = useState(false);
  const [calendarData, setCalendarData] = useState([]);
  const [currentCalendarViewDateRange, setCurrentCalendarViewDateRange] = useState([]); // 目前顯示的日曆的日期範圍
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [highlightedDays, setHighlightedDays] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const AVATARS = useRef([]);

  const isSelectedDate = highlightedDays.includes(dayjs(selectedDate).format('YYYY-MM-DD'));

  function getAvatarGroupPicturesAndNames(data) {
    return data.reduce((acc, item) => {
      acc[item.userId] = {
        portrait: item.portrait,
        name: item.name,
      };
      return acc;
    }, {});
  }

  useEffect(() => {
    // 取得當前月份的第一天
    const firstDayOfMonth = dayjs(selectedDate).startOf("month");
    // 找到日曆顯示的第一個星期日
    const firstDayOfCalendar = firstDayOfMonth.startOf("week").startOf("day");
    // 找到日曆顯示的最後一個星期六
    const lastDayOfMonth = dayjs(selectedDate).endOf("month");
    const lastDayOfCalendar = lastDayOfMonth.endOf("week").endOf("day");;

    setCurrentCalendarViewDateRange([firstDayOfCalendar, lastDayOfCalendar]);
  }, [])

  useEffect(() => {
    if (homeGroupId && currentCalendarViewDateRange?.length === 2 && Object.keys(AVATARS.current).length > 0) {
      setOpenSpinner(true)
      const [startTime, endTime] = toUnixTimestamp(currentCalendarViewDateRange);

      const requestCalendarCron = {
        resource: "/users/0/crons/calendar",
        verb: "read",
        data: {
          filter: {
            homeGroupId,
            startTime,
            endTime
          },
          projection: ["activeDays", "dailyCrons"]
        },
        accessToken: token,
      };

      ws.send(JSON.stringify(requestCalendarCron));

      setCurrentCalendarViewDateRange([]);
    }
  }, [homeGroupId, currentCalendarViewDateRange, AVATARS.current])

  useEffect(() => {
    // 拿 SuperAdmin 和 Elder 資料
    if (wsData.updateTag === "homegroups_read" && wsData.updateData && Object.keys(AVATARS.current).length === 0) {
      const elderMembersData = cloneDeep(wsData);
      const readElderData = elderMembersData.updateData.members
      AVATARS.current = getAvatarGroupPicturesAndNames(readElderData);
    }
    // 拿日曆所有事件
    if (wsData.updateTag === "read_crons_calendar" && wsData.updateData && Object.keys(AVATARS.current).length > 0) {
      const allCronData = cloneDeep(wsData);
      const readData = allCronData.updateData.dailyCrons;
      const formatReadData = readData.flatMap(data =>
        data.crons.map(({ id, cronId, name: title, type, items, startTime, endTime }) => {
          // 判斷是否為自定義事件 actions
          const typeCondition = type === 'actions';
          const elder = typeCondition ? items.elderIds : [items.elderId];

          return {
            id,
            cronId,
            title,
            type: typeCondition ? items?.type : type,
            eventColors: CALENDAR_EVENT_COLORS[typeCondition ? items?.type : type],
            allDay: typeCondition ? items?.allDay : false,
            date: dayjs(data.time).format('YYYY-MM-DD'),
            start: formatTimestampToTime(startTime),
            end: formatTimestampToTime(endTime),
            elder,
            avatar: elder.map(elderId => AVATARS.current[elderId] || ""),
          };
        }).sort((a, b) => {
          if (a.allDay && !b.allDay) return -1;
          if (!a.allDay && b.allDay) return 1;
          return 0;
        })
      );
      setCalendarData(formatReadData);
      setOpenSpinner(false)
    }
  }, [wsData, AVATARS.current])

  useEffect(() => {
    if (calendarData.length > 0) {
      setOpenSpinner(true)
      const highlightDates = [...new Set(calendarData.map(event => event.date))];
      setHighlightedDays(highlightDates);
      setOpenSpinner(false)
    }
  }, [calendarData])

  useEffect(() => {
    if (calendarData.length > 0) {
      setOpenSpinner(true)
      const findCurrentScheduleData = calendarData.filter((item) => (item.date === selectedDate.format('YYYY-MM-DD')));
      setSelectedData(findCurrentScheduleData)
      setOpenSpinner(false)
    }
  }, [calendarData, selectedDate])

  return (
    <CardContent sx={{ padding: '20px 30px 0', height: 'calc(100% - 50px)' }}>
      <Spinner open={openSpinner} />
      {fullScreenArrange && <>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={isSmallScreen ? 12 : 10}>
              <CalendarFeild
                setCalendarData={setCalendarData}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                setCurrentCalendarViewDateRange={setCurrentCalendarViewDateRange}
                highlightedDays={highlightedDays}
              />
            </Grid>
            <Grid item xs={isSmallScreen ? 12 : 2}>
              <ScheduleList
                isSelectedDate={isSelectedDate}
                selectedData={selectedData}
              />
            </Grid>
          </Grid>
        </Box>
      </>
      }
      {!fullScreenArrange && <>
        <CalendarFeild
          setCalendarData={setCalendarData}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          setCurrentCalendarViewDateRange={setCurrentCalendarViewDateRange}
          highlightedDays={highlightedDays}
        />
        <ScheduleList
          isSelectedDate={isSelectedDate}
          selectedData={selectedData}
        />
      </>
      }
    </CardContent>
  )
}

export default Calendar;
