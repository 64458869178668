import React, { useEffect, memo } from 'react';
import './modal-container.component.scss';

import { useFormikContext, Form } from 'formik';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from "@mui/material/Typography";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";

import close from "assets/img/icon/icn_alert_close.svg";

const newOpenTheme = { position: "absolute", top: 8, right: 12, margin: '16px 0' }
const btnStyle = {
  width: '60px',
  height: '40px',
  borderRadius: '10px',
  borderColor: '#0087a9',
  margin: '5px',
  padding: '10px 16px',
  '&:hover': {
    borderColor: '#0087a9',
  }
}

const ModalforForm = ({ title, titleColor, titleSize, isModalOpen, iconOnClick, content, actionButtons }) => {
  const { handleSubmit, isSubmitting } = useFormikContext();

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  return (
    <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
      <Box display='flex' justifyContent='space-between'>
        <Typography color={titleColor || "#0087a9"} fontWeight="600" fontSize={titleSize || "24px"} lineHeight="1.25" margin='16px 0'>
          {title}
        </Typography>
        <IconButton style={newOpenTheme} onClick={iconOnClick}>
          <img src={close} alt="openInNew" width="16px" />
        </IconButton>
      </Box>
      <Divider />
      <Box margin='25px 0' overflow="auto"
        sx={{
          paddingRight: '16px',
          '@media (max-width: 768px)': {
            maxHeight: '300px',
          },
          '@media (min-width: 768px) and (max-width: 1024px)': {
            maxHeight: '400px',
          },
          '@media (min-width: 1024px) and (max-width: 1440px)': {
            maxHeight: '450px',
          },
          '@media (min-width: 1440px) and (max-width: 1920px)': {
            maxHeight: '500px',
          },
          '&::-webkit-scrollbar': {
            width: '4px',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bad4dd',
            borderRadius: '4px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: 'none',
          },
        }}
      >
        {content}
      </Box>
      <Divider />
      <Box display="flex" justifyContent="space-between" marginTop="10px">
        <Box display="flex">
          {actionButtons && actionButtons.filter(Boolean).map((item, index) => (
            item.icon && (
              <Box key={index}>
                <IconButton
                  sx={{ width: '44px', height: '44px', color: 'red' }}
                  disabled={item.disabled}
                  onClick={item.onClick}
                >
                  {item.icon}
                </IconButton>
              </Box>
            )
          ))}
        </Box>

        <Box display="flex">
          {actionButtons && actionButtons.filter(Boolean).map((item, index) => (
            item.text && (
              <Button
                style={{ backgroundColor: item.variant === 'contained' ? '#0087a9' : 'none' }}
                sx={btnStyle}
                type={item.type}
                disabled={item.disabled || isSubmitting}
                key={index}
                variant={item.variant}
                onClick={item.onClick}
              >
                <Typography width="100%" color={item.variant === 'contained' ? '#ffffff' : '#0087a9'} fontSize="14px" fontWeight="600" lineHeight="1.25">
                  {item.text}
                </Typography>
              </Button>
            )
          ))}
        </Box>
      </Box>
    </Form>
  );
};

export default memo(ModalforForm);