import React, { useState, useEffect } from 'react';

import { format } from 'date-fns';
import { zhTW, enUS } from 'date-fns/locale';
import { FormattedMessage, useIntl } from "react-intl";

import { useTheme, useMediaQuery } from '@mui/material';
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { ReactComponent as ArrowBackIcon } from 'assets/img/icon/btn_calendar_arrow_left.svg';
import { ReactComponent as ArrowForwardIcon } from 'assets/img/icon/btn_calendar_arrow_right.svg';

import { CALENDAR_VIEW_OPTIONS } from './const';

export default function Toolbar({ date, view, onClickNext, onClickPrev, onClickToday, onChangeView }) {
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'))

  const intl = useIntl();
  const currentLocale = intl.locale;

  const [viewFilter, setViewFilter] = useState(CALENDAR_VIEW_OPTIONS);

  useEffect(() => {
    if (matchDownSM) {
      const filter = CALENDAR_VIEW_OPTIONS.filter((item) => item.value !== 'dayGridMonth' && item.value !== 'timeGridWeek');
      setViewFilter(filter);
    } else {
      setViewFilter(CALENDAR_VIEW_OPTIONS);
    }
  }, [matchDownSM]);

  return (
    <Stack height="40px" direction="row" alignItems="center" justifyContent="space-between" spacing={matchDownSM ? 1 : 3} sx={{ pb: 3, padding: '10px 0 20px 0' }}>
      <Button sx={{
        height: '100%', padding: '10px 16px', color: '#0087a9', lineHeight: 1.25,
        fontWeight: 600, fontSize: '16px', borderColor: '#0087a9', borderRadius: '10px'
      }}
        variant="outlined" onClick={onClickToday} size={matchDownSM ? 'small' : 'medium'}>
        <FormattedMessage id="app.common.today" />
      </Button>
      <Stack height="100%" direction="row" alignItems="center" spacing={matchDownSM ? 0.5 : 3}>
        <IconButton onClick={onClickPrev} size={matchDownSM ? 'small' : 'large'}>
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ margin: 0, color: '#737791', lineHeight: 1.11, fontSize: '18px', fontWeight: 600 }}>
          {currentLocale === 'zh-TW' ? format(date, 'yyyy 年 MM 月', { locale: zhTW }) : format(date, 'yyyy MMMM', { locale: enUS })}
        </Typography>
        <IconButton onClick={onClickNext} size={matchDownSM ? 'small' : 'large'}>
          <ArrowForwardIcon />
        </IconButton>
      </Stack>
      <ButtonGroup sx={{ height: '100%' }} variant="outlined" aria-label="outlined button group">
        {viewFilter.map((viewOption, index) => {
          const Icon = viewOption.icon;
          return (
            <Tooltip
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: index === viewFilter.length - 1 ? '30px' : '20px',
                    height: '28px',
                    padding: '6px 8px',
                    borderRadius: '2px',
                    lineHeight: 1.43,
                    fontSize: '14px',
                    backgroundColor: '#424962',
                  },
                  '& .MuiTooltip-arrow': {
                    color: '#424962',
                  },
                },
              }} title={viewOption.label} key={viewOption.value} arrow>
              <Button
                sx={{
                  width: '40px',
                  color: viewOption.value === view ? '#ffffff' : '#0087a9',
                  borderRadius: index === 0 ? '10px 0 0 10px' : index === viewFilter.length - 1 ? '0 10px 10px 0' : 'none',
                  borderColor: '#0087a9',
                  padding: '12px',
                  backgroundColor: viewOption.value === view ? '#0087a9' : 'transparent',
                  '&:hover': {
                    backgroundColor: viewOption.value === view ? '#005f6b' : 'rgba(0, 135, 169, 0.08)',
                  }
                }}
                disableElevation
                size={matchDownSM ? 'small' : 'medium'}
                variant={viewOption.value === view ? 'contained' : 'outlined'}
                onClick={() => onChangeView(viewOption.value)}
              >
                <Icon style={{ fontSize: '1rem' }} />
              </Button>
            </Tooltip>
          );
        })}
      </ButtonGroup>
    </Stack>
  );
}
