import React from 'react'

import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';
import { FormattedMessage, useIntl } from "react-intl";

import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";

import { ReactComponent as InputEditIcon } from 'assets/img/icon/icn_input_edit_default.svg';
import { ReactComponent as RemovedBtn } from 'assets/img/icon/icn_table_removed.svg';

import { NormalTextField } from 'mantisComponents/InputTextField';

import DevicesCard from '../components/DevicesCard';

const RoomFields = ({
  currentProsessState,
  devicesData,
  setDevicesData,
  roomFields,
  index,
  hasEditRoomFieldsNameError,
  setEditRoomFieldsNameError,
}) => {
  const intl = useIntl();
  const roomFieldsItems = roomFields.devicesIds.map((itemId) => devicesData.devicesInfo.filter((item) => item.id === itemId)[0]);

  function handleTextFieldChange(event) {
    const machId = event.target.id.match(/room-\d+/)[0];
    const newName = event.target.value;

    let errorMessage = '';
    if (newName === '') {
      errorMessage = <FormattedMessage id="app.errorMessage.cannotBeEmpty" values={{ fieldName: <FormattedMessage id="app.devices.locationName" /> }}/>;
    } else if (/[^a-zA-Z0-9\u4e00-\u9fa5]/.test(newName)) {
      errorMessage = <FormattedMessage id="app.errorMessage.cannotContainSpecialCharacters" values={{ fieldName: <FormattedMessage id="app.devices.locationName" /> }}/>;
    }

    setEditRoomFieldsNameError((prevErrors) => {
      if (errorMessage) {
        return { ...prevErrors, [machId]: errorMessage };
      } else {
        const { [machId]: _, ...remainingErrors } = prevErrors;
        return remainingErrors;
      }
    });

    setDevicesData((prevDevicesData) =>
      prevDevicesData.map((device) => {
        if (device.roomFields) {
          const updatedRoomFields = device.roomFields.map((item) =>
            item.id === machId ? { ...item, name: newName } : item
          );
          return { ...device, roomFields: updatedRoomFields };
        }
        return device;
      })
    );
  }

  function handleRemovedOnClick(roomFieldsId, index) {
    // 檢查場域內是否有裝置 沒有裝置 才能刪除整個 column / room
    if (devicesData.roomFields[index].devicesIds.length !== 0) return;

    setDevicesData((prevDevicesData) =>
      prevDevicesData.map((device) => {
        const updatedRoomFields = device.roomFields.reduce((acc, room) => {
          if (room.id !== roomFieldsId) {
            acc.push(room);
          }
          return acc;
        }, []);

        return { ...device, roomFields: updatedRoomFields };
      })
    );

    setEditRoomFieldsNameError((prevErrors) => {
      const { [roomFieldsId]: _, ...remainingErrors } = prevErrors;
      return remainingErrors;
    });
  }

  return (
    <Draggable draggableId={roomFields.id} index={index}>
      {(provided, snapshot) => (
        <Box
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            minWidth: 300,
            margin: '0 20px',
            height: '100%',
            ...provided.draggableProps.style
          }}
        >
          <Droppable droppableId={roomFields.id} type="item">
            {(providedDrop, snapshotDrop) => (
              <Box
                ref={providedDrop.innerRef}
                {...providedDrop.droppableProps}
              >
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs zeroMinWidth>
                    <Box display='flex' justifyContent='space-evenly' alignItems='center' marginBottom="24px">
                      <Box width="250px">
                        <NormalTextField
                          id={`edit-page-${roomFields.id}`}
                          placeholder={intl.formatMessage({ id: 'app.devices.locationName' })}
                          value={roomFields.name}
                          onChange={handleTextFieldChange}
                          icon={<InputEditIcon />}
                          error={!!hasEditRoomFieldsNameError[roomFields.id]}
                          helperText={hasEditRoomFieldsNameError[roomFields.id]}
                        />
                      </Box>
                      <Box width="24px">
                        <IconButton
                          sx={{
                            top: !!hasEditRoomFieldsNameError[roomFields.id] ? '-13px' : '0px'
                          }}
                          onClick={() => handleRemovedOnClick(roomFields.id, index)}
                        >
                          <RemovedBtn width="24" height="24" />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
                {roomFieldsItems.map((item, i) => (
                  <Draggable key={item.id} draggableId={item.id} index={i}>
                    {(provided, snapshot) => (
                      <DevicesCard
                        key={`editPage-${item.id}`}
                        currentProsessState={currentProsessState}
                        roomFieldName={roomFields.name}
                        devicesCardContent={item}
                        provided={provided}
                      />
                    )}
                  </Draggable>
                ))}
                {providedDrop.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
      )}
    </Draggable>
  );
}

const EditPage = ({
  currentProsessState,
  devicesData,
  setDevicesData,
  hasEditRoomFieldsNameError,
  setEditRoomFieldsNameError,
}) => {
  // handle drag & drop
  function onDragEnd(result) {
    let newRoomFields;
    // source: roomFields 原本位置,  destination: roomFields 新的位置, draggableId: roomFields 的 id, type: 類型, column / item
    const { source, destination, draggableId, type } = result;
    // 如果沒有 destination 回到原本的位置 
    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    if (type === 'column') {
      // 深拷貝取出 roomFieldsOrder, roomFieldsOrder 是目前 roomFields 的順序
      const newRoomFieldsOrder = Array.from(devicesData[0].roomFieldsOrder);

      newRoomFieldsOrder.splice(source.index, 1); // remove dragged column
      newRoomFieldsOrder.splice(destination?.index, 0, draggableId); // set column new position

      setDevicesData((currentDevicesData) => {
        const newDevicesData = [...currentDevicesData];
        if (newDevicesData.length > 0) {
          newDevicesData[0] = {
            ...newDevicesData[0],
            roomFieldsOrder: newRoomFieldsOrder,
          };
        }
        return newDevicesData;
      });

      return;
    }

    // find dragged item's column
    const sourceRoomFields = devicesData[0].roomFields.filter((item) => item.id === source.droppableId)[0];

    // find dropped item's column 
    const destinationRoomFields = devicesData[0].roomFields.filter((item) => item.id === destination.droppableId)[0];

    // if - moving items in the same list
    // else - moving items from one list to another
    if (sourceRoomFields === destinationRoomFields) {
      const newDevicesIds = Array.from(sourceRoomFields.devicesIds);

      // remove the id of dragged item from its original position
      newDevicesIds.splice(source.index, 1);

      // insert the id of dragged item to the new position
      newDevicesIds.splice(destination.index, 0, draggableId);

      // updated column
      const newSourceRoomFields = {
        ...sourceRoomFields,
        devicesIds: newDevicesIds
      };

      newRoomFields = devicesData && devicesData[0].roomFields.map((column) => {
        if (column.id === newSourceRoomFields.id) {
          return newSourceRoomFields;
        }
        return column;
      });
    } else {
      const newSourceDevicesIds = Array.from(sourceRoomFields.devicesIds);

      // remove the id of dragged item from its original column
      newSourceDevicesIds.splice(source.index, 1);

      // updated dragged items's column
      const newSourceRoomFields = {
        ...sourceRoomFields,
        devicesIds: newSourceDevicesIds
      };

      const newDestinationDevicesIds = Array.from(destinationRoomFields.devicesIds);

      // insert the id of dragged item to the new position in dropped column
      newDestinationDevicesIds.splice(destination.index, 0, draggableId);

      // updated dropped item's column
      const newDestinationRoomFields = {
        ...destinationRoomFields,
        devicesIds: newDestinationDevicesIds
      };

      newRoomFields = devicesData && devicesData[0].roomFields.map((column) => {
        if (column.id === newSourceRoomFields.id) {
          return newSourceRoomFields;
        }
        if (column.id === newDestinationRoomFields.id) {
          return newDestinationRoomFields;
        }
        return column;
      });
    }

    setDevicesData((currentDevicesData) => {
      const newDevicesData = [...currentDevicesData];
      if (newDevicesData.length > 0) {
        newDevicesData[0] = {
          ...newDevicesData[0],
          roomFields: newRoomFields,
        };
      }
      return newDevicesData;
    });
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="columns" direction="horizontal" type="column">
        {(provided) => (
          <Box
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{
              display: 'flex',
              gap: 2,
              padding: 2,
              whiteSpace: 'nowrap',
              overflowX: 'auto',
              '&::-webkit-scrollbar': {
                height: '4px',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: '#bad4dd',
                borderRadius: '4px',
              },
              '&::-webkit-scrollbar-track': {
                backgroundColor: 'none',
              },
            }}
          >
            {devicesData && devicesData[0].roomFieldsOrder.map((columnId, index) => {
              const roomFields = devicesData[0].roomFields.find((item) => item.id === columnId);
              return (
                roomFields && (
                  <RoomFields
                    key={columnId}
                    currentProsessState={currentProsessState}
                    devicesData={devicesData[0]}
                    setDevicesData={setDevicesData}
                    roomFields={roomFields}
                    index={index}
                    hasEditRoomFieldsNameError={hasEditRoomFieldsNameError}
                    setEditRoomFieldsNameError={setEditRoomFieldsNameError}
                  />
                )
              );
            })}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default EditPage;