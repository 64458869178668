import summaryStyle from '../summary.module.scss';
import React, { useState, useEffect, useRef, useContext } from "react";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import { cloneDeep, isNil } from 'lodash';
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../../hook/AuthContext";
import { FormattedMessage } from "react-intl";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { useMediaQuery, useTheme } from "@mui/material";
import Typography from '@mui/material/Typography';
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import upper from "assets/img/icon/btn_arrow_dropdown_down.svg";
import down from "assets/img/icon/btn_arrow_dropdown_upper.svg";
import note from "assets/img/icon/icn_abnormal.svg";
import calendar from "assets/img/icon/icn_menu_schedule.svg";
// Controller
import controller from '../controller';
import { DetailSummaryData } from "../simuData";

dayjs.extend(utc);
dayjs.extend(timezone);

const UpperIcon = (props) => (
  <img
    src={upper}
    alt="upper"
    style={{
      width: 20,
      pointerEvents: "none",
    }}
    {...props}
  />
);

const DownIcon = (props) => (
  <img
    src={down}
    alt="down"
    style={{
      width: 20,
      pointerEvents: "none",
    }}
    {...props}
  />
);

const CalendarIcon = () => (
  <img src={calendar} alt="Calendar" />
);

const localStorageId = 'SUMMARY_DETAIL';
const TimeLine = (props) => {
  const { wsData, setWsData, card, openSpinner, setOpenSpinner } = props;
  const ws = useWebSocket();
  const { token } = useAuth();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(960));
  // const renderLayout = useRef(false);
  let now = dayjs();
  let limitday = dayjs().subtract(365, 'day');
  let rangeday = dayjs().subtract(15, 'day');

  const highSBP = 120, lowSBP = 90, highDBP = 80, lowDBP = 60, highPluse = 100, lowPluse = 60;

  const [startDate, setStartDate] = useState({
    current: controller.getDateYMD(rangeday),
    max: controller.getDateYMD(now),
    min: controller.getDateYMD(limitday)
  });
  const [endDate, setEndDate] = useState({
    current: controller.getDateYMD(now),
    max: controller.getDateYMD(now),
    min: controller.getDateYMD(limitday)
  });
  const [filterDate, setFilterDate] = useState({
    startTimeStamp: parseInt(rangeday.format('X')),
    endTimeStamp: parseInt(now.format('X')),
  });

  const [healthData, setHealthData] = useState([]);

  function handleShowDetail(groupId) {
    //console.log(groupId)
    const newData = [...healthData];
    for (let item of newData) {
      if(item.gId === groupId){
        let ifShow = item.showFullInfo
        item.showFullInfo = !ifShow;
      }else{
        item.showFullInfo = false;
      }
    }
    // console.log(newData)
    setHealthData(newData);

    // save data to local storage
    // localStorage.setItem(localStorageId, JSON.stringify(newData));
  }

  function handleDateChange(date, from){
    //get the 0:00 time stamp of changeDate
    let change = controller.getDateYMD(date);
    let newFilter = cloneDeep(filterDate);
    let newStartDateObj = cloneDeep(startDate);
    let newEndDateObj = cloneDeep(startDate);

    if(from === 'start'){
      newStartDateObj.current = change;
      setStartDate(newStartDateObj)

      let changeEnd = date.add(15, 'day').isAfter(newEndDateObj.max) ? newEndDateObj.max : date.add(15, 'day')
      newEndDateObj.current = changeEnd;
      setEndDate(newEndDateObj)

      newFilter.startTimeStamp = parseInt(dayjs(change).format('X'))
      newFilter.endTimeStamp = parseInt(dayjs(changeEnd).format('X'))+86399
      setFilterDate(newFilter)
    }else{ // click end
      newEndDateObj.current = change;
      setEndDate(newEndDateObj)

      let changeStart = date.subtract(15, 'day').isBefore(newStartDateObj.min) ? newStartDateObj.min : date.subtract(15, 'day')
      newStartDateObj.current = changeStart;
      setStartDate(newStartDateObj)

      newFilter.startTimeStamp = parseInt(dayjs(changeStart).format('X'))
      newFilter.endTimeStamp = parseInt(dayjs(change).format('X'))+86399
      setFilterDate(newFilter);
    }

    // console.log(newFilter)
    getData(newFilter)
  }

  function addNewWsData(newDataAry, type) {
    let updateData = []; //[...healthData];
    if (type !== 'read'){
      updateData = cloneDeep(healthData);
    }

    for (let newData of newDataAry) {
      let newDateY = dayjs(newData.data.timestamp*1000).format('YYYY');
      let newDateM = dayjs(newData.data.timestamp*1000).format('MM');
      let newDateD = dayjs(newData.data.timestamp*1000).format('DD');

      let newDateStamp = dayjs(newDateY+'-'+newDateM+'-'+newDateD).format('X');
      let findDateGroup = false;
      let eventNote = {
        systolic: (lowSBP <= newData.data.systolic &&  newData.data.systolic<= highSBP)? 'normal':'',
        diastolic: (lowDBP <= newData.data.diastolic && newData.data.diastolic<= highDBP)? 'normal':'',
        heartRate: (lowPluse <= newData.data.heartRate && newData.data.heartRate<= highPluse)? 'normal':'',
      }      

      for (let item of updateData) {
        // find date
        if(item.year === newDateY && item.month === newDateM && item.day === newDateD){
          findDateGroup = true;
          let detailObj = {
            timestamp: newData.data.timestamp,
            SBP: newData.data.systolic,
            DBP: newData.data.diastolic,
            PULSE: newData.data.heartRate,
            noteSBP: eventNote.systolic,
            noteDBP: eventNote.diastolic,
            notePULSE: eventNote.heartRate,
            hasNote: true
          }
          if(detailObj.noteSBP === 'normal' && detailObj.noteDBP === 'normal' && detailObj.notePULSE === 'normal' ){
            detailObj.hasNote = false
          }
          // add date to detail & calc avg
          if (type === 'read'){
            item.fullContent.push(detailObj);
          }else if(type === 'update'){
            item.fullContent.unshift(detailObj);
          }

          let num = item.fullContent.length;
          let avgS =0;
          let avgD =0;
          let avgP =0;
          for (let subitem of item.fullContent) {
            avgS+= subitem.SBP;
            avgD+= subitem.DBP;
            avgP+= subitem.PULSE
          }
          item.avgSBP = Math.round(avgS/num);
          item.avgDBP = Math.round(avgD/num);
          item.avgPULSE = Math.round(avgP/num);
        }
        item.showFullInfo = false;
      }
      if(!findDateGroup){
        let groupObj = {
          gId: updateData.length+1,
          avgSBP: newData.data.systolic,
          avgDBP: newData.data.diastolic,
          avgPULSE: newData.data.heartRate,
          year: newDateY,
          month: newDateM,
          day: newDateD,
          hour: '0', //dayjs().format('HH'),
          dateStamp: newDateStamp,
          showFullInfo: false,
          fullContent: [
            {
              timestamp: newData.data.timestamp,
              SBP: newData.data.systolic,
              DBP: newData.data.diastolic,
              PULSE: newData.data.heartRate,
              noteSBP: eventNote.systolic,
              noteDBP: eventNote.diastolic,
              notePULSE: eventNote.heartRate,
              hasNote: true
            },
          ]
        }
        if(groupObj.fullContent[0].noteSBP === 'normal' && groupObj.fullContent[0].noteDBP === 'normal' && groupObj.fullContent[0].notePULSE === 'normal' ){
          groupObj.fullContent[0].hasNote = false
        }
        //add new date group
        if (type === 'read'){
          updateData.push(groupObj)
        }else if(type === 'update'){
          updateData.unshift(groupObj)
        }
      }

    }
    if(updateData.length > 0){
      updateData[0].showFullInfo = true;
    }

    setHealthData(updateData);
    setOpenSpinner(false);
    // save data to local storage
    // localStorage.setItem(localStorageId, JSON.stringify(updateData));
  }

  const getData = async (newFilter) => {
    let emptyData = []
    setHealthData(emptyData)
    setOpenSpinner(true);
    // save data to local storage
    // localStorage.setItem(localStorageId, JSON.stringify(emptyData));

    const chartSendData = {
      resource: `/users/${card[0].userId}/reports`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: newFilter.startTimeStamp,
          endTime: newFilter.endTimeStamp,
          homeGroupId: card[0].homeId,
        },
        projection: ['reports']
      },
    };

    ws.send(JSON.stringify(chartSendData));
  }

  useEffect(() => {
    if(wsData.updateTag === 'report_creat' && wsData.updateData){
      console.log('timeline summary get creat ws')
      console.log(wsData.updateData)
      let tmpNewData = [
        { data: wsData.updateData }
      ]
      addNewWsData(tmpNewData, 'update');
    }else if(wsData.updateTag === 'report_read' && wsData.updateData){

      if(wsData.updateData.reports !== null){
        console.log('timeline summary get read ws')
        console.log(wsData.updateData)

        let emptyData = []
        setHealthData(emptyData)

        // save data to local storage
        // localStorage.setItem(localStorageId, JSON.stringify(emptyData));

        let tmpData = cloneDeep(wsData.updateData)
        let sortData = []
        if(tmpData.reports !== null){
          sortData = tmpData.reports.sort(sortTime)
        }
        addNewWsData(sortData, 'read');
      }

    }
  }, [wsData]);

  useEffect(() => {
    // let tmpData = localStorage.getItem(localStorageId)
    // if(healthData.length === 0 && tmpData !== null){
    //   setHealthData(JSON.parse(tmpData));
    // }
  }, [healthData]);

  function sortTime(a,b){
    return b.data.timestamp-a.data.timestamp;
  }

  return (
    <>
      <Box style={{marginBottom:'35px'}}>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Typography variant="h6" component="div" color="#424962" fontWeight={'600'}>
              <FormattedMessage id="app.summary.historicalRecords" />
            </Typography>
          </Grid>
          <Grid item>
            <Grid container alignItems="center" spacing={2}>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={startDate.current}
                      maxDate={startDate.max}
                      minDate={startDate.min}
                      slots={{ openPickerIcon: CalendarIcon }}
                      onChange={(newValue) => {
                        handleDateChange(newValue, 'start');
                      }}
                      format="YYYY/M/D"
                      slotProps={{
                        day: {
                          sx: { "&.MuiPickersDay-root.Mui-selected": { backgroundColor: "#0A87A9", }, },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': { borderColor: '#eef0f2', margin: '5px 0px'},
                          '&:hover fieldset': { borderColor: '#bad4dd', },
                          '&.Mui-focused fieldset': { borderColor: '#bad4dd', },
                        },
                        width: "150px",
                        "& .MuiOutlinedInput-notchedOutline": { 
                          borderColor: "#bad4dd", 
                          borderRadius: "12px"
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
              <Grid item>~</Grid>
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                      value={endDate.current}
                      maxDate={endDate.max}
                      minDate={endDate.min}
                      slots={{ openPickerIcon: CalendarIcon }}
                      onChange={(newValue) => {
                        handleDateChange(newValue, 'end');
                      }}
                      format="YYYY/M/D"
                      slotProps={{
                        day: {
                          sx: { "&.MuiPickersDay-root.Mui-selected": { backgroundColor: "#0A87A9", }, },
                        },
                      }}
                      sx={{
                        '& .MuiOutlinedInput-root': {
                          '& fieldset': { borderColor: '#eef0f2', margin: '5px 0px'},
                          '&:hover fieldset': { borderColor: '#bad4dd', },
                          '&.Mui-focused fieldset': { borderColor: '#bad4dd', },
                        },
                        width: "150px",
                        "& .MuiOutlinedInput-notchedOutline": { 
                          borderColor: "#bad4dd", 
                          borderRadius: "12px"
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box>
      {healthData.map((group, index) => (
        <div key={index}>
          <Grid container alignItems="center" justifyContent="start" spacing={2}
            // style={{minWidth: '1024px', overflow:'scroll'}}
            >
            <Grid item sx={{marginTop: '10px'}}>
              <IconButton onClick={() =>handleShowDetail(group.gId)}>
                {group.showFullInfo? <DownIcon />:<UpperIcon/> }
              </IconButton>
            </Grid>
            <Grid item sx={{width: '65px', marginTop: '10px'}}>
              <Typography variant="h7" component="div" color="#424962" style={{fontWeight:"bold",}}>
                {group.month}/{group.day}
              </Typography>
            </Grid>
            <Grid item style={{marginTop: '10px'}}>
              <Typography variant="h7" component="div" color="#424962" style={{fontWeight:"bold",}}>
                <FormattedMessage id="app.summary.average" />
              </Typography>
            </Grid>
            <Grid item style={{marginLeft: '30px', width: '85px'}}>
              <Stack alignItems="center">
              <Typography variant="h7" component="div" color="#737791">
                <FormattedMessage id="app.common.systolicBloodPressure" />
              </Typography>
              <span style={{fontSize: '40px',color:`${group.avgSBP>highSBP||group.avgSBP<lowSBP ? "#e80000":"#737791"}`}}>{group.avgSBP}</span>
              </Stack>
            </Grid>
            <Grid item style={{marginLeft: '10px', width: '85px'}}>
              <Stack alignItems="center">
              <Typography variant="h7" component="div" color="#737791">
                <FormattedMessage id="app.common.diastolicBloodPressure" />
              </Typography>
              <span style={{fontSize: '40px',color:`${group.avgDBP>highDBP||group.avgDBP<lowDBP ? "#e80000":"#737791"}`}}>{group.avgDBP}</span>
              </Stack>
            </Grid>
            <Grid item style={{marginLeft: '10px', width: '85px'}}>
              <Stack alignItems="center">
              <Typography variant="h7" component="div" color="#737791">
                <FormattedMessage id="app.common.heartRate" />
              </Typography>
              <span style={{fontSize: '40px',color:`${group.avgPULSE>highPluse||group.avgPULSE<lowPluse ? "#e80000":"#737791"}`}}>{group.avgPULSE}</span>
              </Stack>
            </Grid>
          </Grid>
          <Divider style={{marginBottom: '20px'}}/>
          {group.showFullInfo &&
            <Timeline position="alternate" style={{margin: '0px 0px 50px 0px', }}>
              {group.fullContent.map((content, index) => (
                <TimelineItem key={index} style={{minHeight: '80px'}}>        
                  <TimelineSeparator style={{ marginLeft: '15px', paddingTop: '35px'}}
                    className={`${index%2===0? summaryStyle["timelineObj2"] : summaryStyle["timelineObj1"]}`}>
                    {/* <TimelineConnector /> */}
                    <div className={summaryStyle['timeline']}>
                      <span>{dayjs(content.timestamp*1000).format('HH:mm')}</span>
                    </div>
                    {/* <TimelineConnector /> */}
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: '12px', px: 2 }} style={{position: "relative"}}>
                    {/* {!isSmallScreen && */}
                    {!isSmallScreen &&
                    <div style={{ background: (content.hasNote? '#FFF4F4':''), border: (content.hasNote? '':'1px solid #BAD4DD'), borderRadius: '20px',
                                  display:'flex', width:'fit-content', position:'absolute', right: (index%2===0? '':'0px'), padding: '5px 0px'}}>
                      <div style={{marginLeft: '5px',paddingTop: '25px'}}>
                        {content.hasNote && <img src={note} style={{marginLeft: '15px',width: '30px'}}/>}
                      </div>
                      <div>
                        <div style={{marginLeft: '20px', marginRight: '15px', display:'inline-block', alignContent:'center', textAlign: 'start'}}>
                          <Typography variant="h7" component="div" color="#737791">
                            <FormattedMessage id="app.common.systolicBloodPressure" />
                          </Typography>
                          <span style={{lineHeight: '1.2', fontSize: '40px',color:`${content.noteSBP==='normal' ? "#737791":"#e80000"}`}}>{content.SBP}</span>
                        </div>
                        <div style={{marginLeft: '15px', marginRight: '15px', display:'inline-block', alignContent:'center', textAlign: 'start'}}>
                          <Typography variant="h7" component="div" color="#737791">
                            <FormattedMessage id="app.common.systolicBloodPressure" />
                          </Typography>
                          <span style={{lineHeight: '1.2', fontSize: '40px',color:`${content.noteDBP==='normal' ? "#737791":"#e80000"}`}}>{content.DBP}</span>
                        </div>
                        <div style={{marginLeft: '15px', marginRight: '25px', display:'inline-block', alignContent:'center', textAlign: 'start'}}>
                          <Typography variant="h7" component="div" color="#737791">
                            <FormattedMessage id="app.common.heartRate" />
                          </Typography>
                          <span style={{lineHeight: '1.2', fontSize: '40px',color:`${content.notePULSE==='normal' ? "#737791":"#e80000"}`}}>{content.PULSE}</span>
                        </div>
                      </div>
                    </div>}
                    {isSmallScreen &&
                    <div style={{ background: (content.hasNote? '#FFF4F4':''), border: (content.hasNote? '':'1px solid #BAD4DD'), borderRadius: '20px',
                                  display:'flex', width:'fit-content', position:'absolute', right: (index%2===0? '':'0px'), padding: '5px 0px'}}>
                      <div style={{marginLeft: '5px',paddingTop: '25px'}}>
                      {content.hasNote && <img src={note} style={{marginLeft: '15px',width: '30px'}}/>}
                      </div>
                      <div>
                        <div style={{marginLeft: '10px', marginRight: '10px', display:'inline-block', alignContent:'center', textAlign: 'start'}}>
                          <Typography variant="h7" component="div" color="#737791">
                            <FormattedMessage id="app.common.systolicBloodPressure" />
                          </Typography>
                          <span style={{lineHeight: '1.2', fontSize: '40px',color:`${content.noteSBP==='normal' ? "#737791":"#e80000"}`}}>{content.SBP}</span>
                        </div>
                        <div style={{marginLeft: '10px', marginRight: '10px', display:'inline-block', alignContent:'center', textAlign: 'start'}}>
                          <Typography variant="h7" component="div" color="#737791">
                            <FormattedMessage id="app.common.systolicBloodPressure" />
                          </Typography>
                          <span style={{lineHeight: '1.2', fontSize: '40px',color:`${content.noteDBP==='normal' ? "#737791":"#e80000"}`}}>{content.DBP}</span>
                        </div>
                        <div style={{marginLeft: '10px', marginRight: '10px', display:'inline-block', alignContent:'center', textAlign: 'start'}}>
                          <Typography variant="h7" component="div" color="#737791">
                            <FormattedMessage id="app.common.heartRate" />
                          </Typography>
                          <span style={{lineHeight: '1.2', fontSize: '40px',color:`${content.notePULSE==='normal' ? "#737791":"#e80000"}`}}>{content.PULSE}</span>
                        </div>
                      </div>
                    </div>}
                  </TimelineContent>
                </TimelineItem>
              ))}
            </Timeline>
          }
        </div>
      ))}
      </Box>
    </>

  );
}

export default TimeLine;