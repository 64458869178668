import { FormattedMessage } from "react-intl";

import AIBOXDefaultImage from 'assets/img/icon/img_ai_box_online.svg';
import PlugDefaultImage from 'assets/img/icon/img_plug_online.svg';
import MS30DefaultImage from 'assets/img/icon/img_ms30_online.svg';
import mmWaveDefaultImage from 'assets/img/icon/img_mmwave_online.svg';
import AIBOXDisabledImage from 'assets/img/icon/img_ai_box_disabled.svg';
import PlugDisabledImage from 'assets/img/icon/img_plug_disabled.svg';
import MS30DisabledImage from 'assets/img/icon/img_ms30_disabled.svg';
import mmWaveDisabledImage from 'assets/img/icon/img_mmwave_disabled.svg';

// 2024Q4 plug 跟 light 是一樣的東西
// 未來會合併
// 合併之後可以將 light 相關判斷移除

export const isPoweringState = {
	online: true,
	offline: false,
	on: true,
	off: false,
};

export const deviceStatusLabels = {
	online: <FormattedMessage id="app.common.online" />,
	offline: <FormattedMessage id="app.common.offline" />,
	on: <FormattedMessage id="app.common.on" />,
	off: <FormattedMessage id="app.common.off" />,
}

export const productModelDefaultImg = {
	box: AIBOXDefaultImage,
	plug: PlugDefaultImage,
	light: PlugDefaultImage,
	MS30: MS30DefaultImage,
	mmWave: mmWaveDefaultImage,
}

export const productModelDisabledImg = {
	box: AIBOXDisabledImage,
	plug: PlugDisabledImage,
	light: PlugDefaultImage,
	MS30: MS30DisabledImage,
	mmWave: mmWaveDisabledImage,
}