// page utils
import mainStyle from "./header.module.scss";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useOutletContext } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Avatar from "@mui/material/Avatar";
import dayjs from "dayjs";

import { FormattedMessage } from "react-intl";

import * as Setting from "pages/Login/Setting";
import { useSelector, useDispatch } from "react-redux";
import { counterActions, fetchCount } from "stores/slice/counter";
import { userActions, fetchUser } from "stores/slice/user";

import { useMediaQuery, useTheme } from "@mui/material";
// images
import { ReactComponent as Logo } from "assets/img/icon/logo.svg";
import { ReactComponent as Event } from "assets/img/icon/icn_nav_event.svg";
import { ReactComponent as Language } from "assets/img/icon/icn_nav_language.svg";
import { ReactComponent as SelectDropdownDownBtn } from 'assets/img/icon/btn_arrow_dropdown_down.svg';
import { ReactComponent as SelectDropdownUpperBtn } from 'assets/img/icon/btn_arrow_dropdown_upper.svg';
import { ReactComponent as User } from "assets/img/icon/icn_nav_user.svg";
import { ReactComponent as Weather } from "assets/img/icon/icn_nav_weather_cloudy.svg";
import { ReactComponent as Clock } from "assets/img/icon/icn_nav_clock.svg";
import { ReactComponent as Upper } from "assets/img/icon/btn-arrow-dropdown-down.svg";
import { ReactComponent as Search } from "assets/img/icon/icn_magnifier.svg";

import { useNotification } from "../../hook/NotificationContext";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";

const HeaderSelector = ({ rightMargin, value, onChange, options }) => {
  const [open, setOpen] = useState(false);

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginLeft: rightMargin + "px",
      }}
    >
      <Language style={{ marginRight: "10px" }} />
      <FormControl size="small">
        <Select
          MenuProps={{
            sx: {
              "&& .Mui-selected": {
                backgroundColor: "#ebf5ff",
              },
              "&& .MuiMenuItem-root": {
                color: "#737791",
                "&:hover": {
                  backgroundColor: "#eef0f2",
                },
              },
              "&& .MuiMenuItem-root:hover": {
                backgroundColor: "#eef0f2",
              },
            },
            PaperProps: {
              sx: {
                width: "200px",
                borderRadius: "10px",
                boxShadow: "0 8px 16px 0 rgba(0, 0, 0, 0.08)",
                "& .MuiMenuItem-root:first-of-type": {
                  marginTop: "-8px",
                  borderTopLeftRadius: "10px",
                  borderTopRightRadius: "10px",
                },
                "& .MuiMenuItem-root:last-of-type": {
                  marginBottom: "-8px",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                },
              },
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "right",
            },
          }}
          sx={{
            "& .MuiSelect-select": { color: "#424962" },
            "& .MuiSelect-icon": {
              top: "50%",
              transform: "translateY(-50%)",
            },
            "&.MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: "#F5F8FF",
                margin: "5px 0px",
              },
              "&:hover fieldset": { borderColor: "#bad4dd" },
              "&.Mui-focused fieldset": {
                borderColor: "#F5F8FF",
                boxShadow: "0 0 0 2px rgba(186, 212, 221, 0.25)"
              },
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderRadius: "12px",
            },
          }}
          value={value}
          onChange={onChange}
          displayEmpty
          IconComponent={open ? SelectDropdownUpperBtn : SelectDropdownDownBtn}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
        >
          {
            options.map((item, index)=>(
              <MenuItem key={`${item.name}-${index}`} value={item.value}>{item.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  );
};

const Header = (props) => {
  const { unreadCount, setUnReadCount } = useNotification();

  const tmp_userInfo = useSelector((state) => state.user.userInfo);
  const homeGroupId = useSelector(
    (state) => state.user.userInfo
  ).lastUsedHomegroupId;
  const dispatch = useDispatch();
  const currentTime = Math.floor(new Date().getTime() / 1000);

  const { wsData, setWsData, language, setLanguage } = props;
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const ws = useWebSocket();
  const { token, expiresTime } = useAuth();
  const grayColor = "#424962";

  const [startDate, setStartDate] = useState(dayjs().subtract(15, "day"));
  const startOfStartDate = startDate.startOf("day");
  const startDateTimestamp = startOfStartDate.unix();

  const [endDate, setEndDate] = useState(dayjs());
  const endOfEndDate = endDate.endOf("day");
  const endDateTimestamp = endOfEndDate.unix();

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1024));
  const isMiniScreen = useMediaQuery(theme.breakpoints.down(960));
  const [rightMargin, setRightMargin] = useState(30);

  const languageOptions = [
    {
      name: "繁體中文",
      value: "zh-TW"
    },
    {
      name: "English",
      value: "en-US"
    }
  ];

  useEffect(() => {
    if (isSmallScreen) {
      if (isMiniScreen) {
        setRightMargin(0);
      } else {
        setRightMargin(10);
      }
    } else {
      setRightMargin(30);
    }
  }, [isSmallScreen, isMiniScreen]);

  useEffect(() => {
    if (currentTime < expiresTime) {
      Setting.getUserinfo().then((res) => {
        console.log(res);
        if (res?.status === "ok") {
          // tmp_userInfo = Object.assign(tmp_userInfo, res.data)
          // redux
          dispatch(
            userActions.setUser({
              // userInfo : tmp_userInfo
              userInfo: res.data,
            })
          );
        } else {
          /* Setting.showMessage(`getUserinfo() header error: ${res?.msg}`); */
          localStorage.clear();
          Setting.goToLink("/");
        }
      });
      // console.log('header dispatch')
      // console.log(test)
      // console.log(dispatch)

      // dispatch(fetchCount(test));

      // 清理函数，用于组件卸载时清除用户数据
      // return () => {
      //   dispatch(clearUser());
      // };
    } else {
      localStorage.clear();
      Setting.goToLink("/");
    }
  }, [dispatch]);

  const navigate = useNavigate();
  const handleClick = () => {
    navigate("/event");
  };

  const getCurrentTime = () => {
    const now = new Date();
    const hours = now.getHours().toString().padStart(2, "0");
    const minutes = now.getMinutes().toString().padStart(2, "0");
    setTime(`${hours}:${minutes}`);
  };

  const getCurrentDate = () => {
    const now = new Date();
    const day = now.getDate().toString().padStart(2, "0");
    const month = now.toLocaleString("en-US", { month: "short" });
    setDate(`${day} ${month}`);
  };

  const base64String = tmp_userInfo.portrait;

  useEffect(() => {
    getCurrentTime();
    getCurrentDate();
    const timer = setInterval(getCurrentTime, 1000);
    return () => clearInterval(timer);
  }, []);

  const getData = async () => {
    const eventSendData = {
      resource: `/boxes/0/events`,
      verb: "read",
      accessToken: token,
      data: {
        filter: {
          startTime: startDateTimestamp,
          endTime: endDateTimestamp,
          category:
            "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness, takeMedicine, takeBloodPressure, outside, hospital, other, deviceChange",
          userId: tmp_userInfo.id,
          homeGroupId: tmp_userInfo.lastUsedHomegroupId,
        },
      },
    };
    ws.send(JSON.stringify(eventSendData));
  };

  useEffect(() => {
    if (homeGroupId) {
      getData();
    }
  }, [homeGroupId]);

  useEffect(() => {
    if (
      wsData.updateTag === "event_read" &&
      wsData.updateData &&
      wsData.updateData.category ===
      "reportAbnormal, notTakeBloodPressure, notTakeMedicine, abuse, falldown, sleepingSickness, takeMedicine, takeBloodPressure, outside, hospital, other, deviceChange"
    ) {
      const { ackCount } = wsData.updateData;
      localStorage.setItem("ackCount", ackCount);
      const localAckCount = parseInt(localStorage.getItem("ackCount"), 10);
      setUnReadCount(localAckCount);

      console.log("Ack Count:", unreadCount);
    } else if (
      wsData.updateTag === "abnormalEvent" &&
      wsData.updateData &&
      wsData.updateData.homeGroupId === homeGroupId // 同一個homeGroupId才會+1
    ) {
      console.log("abnormalEvent update ws", wsData.updateData);
      let localAckCount = parseInt(localStorage.getItem("ackCount")) || 0;
      localAckCount = localAckCount + 1;

      localStorage.setItem("ackCount", localAckCount.toString());
      const localGetAckCount = parseInt(localStorage.getItem("ackCount")) || 0;
      setUnReadCount(localGetAckCount);
    }
  }, [wsData]);

  /*   console.log(unreadCount) */

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <>
      <div className={mainStyle["headerBox"]}>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ width: "140px", height: "30px" }}>
              <Logo />
            </div>
            <Divider
              orientation="vertical"
              variant="top"
              style={{
                margin: "0 20px 0 10px",
                height: "72px",
                color: "#bad4dd",
              }}
            />
            <Typography
              component="div"
              color={grayColor}
              fontWeight="normal"
              fontSize="24px"
            >
              <FormattedMessage id="app.common.header.welcome" values={{ name: tmp_userInfo.name }} />
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {/* Time */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: rightMargin + "px",
              }}
            >
              <Clock />
              <Box
                ml={2}
                display="flex"
                flexDirection="column"
                justifyContent="center"
              >
                <div
                  style={{
                    color: grayColor,
                    fontWeight: "normal",
                    whiteSpace: "nowrap",
                    fontSize: "13px",
                  }}
                >
                  {date}
                </div>
                <div
                  style={{
                    color: grayColor,
                    fontWeight: "normal",
                    fontSize: "16px",
                  }}
                >
                  {time}
                </div>
              </Box>
            </Box>

            {/* Event */}
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: rightMargin + "px",
              }}
            >
              <IconButton onClick={handleClick}>
                <Badge color="error" badgeContent={unreadCount}>
                  <Event />
                </Badge>
              </IconButton>
            </Box>

            {/* Language */}

            <HeaderSelector
              rightMargin={rightMargin}
              value={language}
              options={languageOptions}
              onChange={handleLanguageChange}
            />

            {/* User */}

            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "139px",
                marginLeft: rightMargin + "px",
              }}
            >
              <Avatar
                sx={{
                  width: 40,
                  height: 40,
                  backgroundColor: base64String ? "transparent" : "gray",
                  border: "2px solid #d3d3d3",
                  borderRadius: "999px",
                }}
                style={{ marginRight: "10px" }}
              >
                {base64String ? (
                  <img
                    src={base64String}
                    alt={tmp_userInfo?.name}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <User />
                )}
              </Avatar>

              <Typography
                component="div"
                color="grayColor"
                fontWeight="normal"
                style={{
                  whiteSpace: "nowrap",
                  fontSize: "16px",
                }}
              >
                {tmp_userInfo.name}
              </Typography>
              <IconButton
                sx={{
                  maxWidth: "50%",
                  height: "auto",
                  "&:hover": {
                    cursor: "not-allowed",
                  },
                }}
                disableRipple
              >
                <Upper />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </div>
    </>
  );
};

export default Header;
