import enCommon from "lang/en-US/common.json";
import enErrorMessage from "lang/en-US/errorMessage.json";
import enDashboard from "lang/en-US/dashboard.json";
import enCareInformation from "lang/en-US/careInformation.json";
import enDevices from "lang/en-US/devices.json";
import enSummary from "lang/en-US/summary.json";
import enCalendar from "lang/en-US/calendar.json";
import enEvent from "lang/en-US/event.json";

import zhCommon from "lang/zh-TW/common.json";
import twErrorMessage from "lang/zh-TW/errorMessage.json";
import zhDashboard from "lang/zh-TW/dashboard.json";
import zhCareInformation from "lang/zh-TW/careInformation.json";
import zhDevices from "lang/zh-TW/devices.json";
import zhSummary from "lang/zh-TW/summary.json";
import zhCalendar from "lang/zh-TW/calendar.json";
import zhEvent from "lang/zh-TW/event.json";

const messages = {
  "en-US": {
    common: enCommon,
    errorMessage: enErrorMessage,
    dashboard: enDashboard,
    careInformation: enCareInformation,
    devices: enDevices,
    summary: enSummary,
    calendar: enCalendar,
    event: enEvent
  },
  "zh-TW": {
    common: zhCommon,
    errorMessage: twErrorMessage,
    dashboard: zhDashboard,
    careInformation: zhCareInformation,
    devices: zhDevices,
    summary: zhSummary,
    calendar: zhCalendar,
    event: zhEvent
  },
};

export const getMessages = (locale) => {
  const localeMessages = messages[locale] || {};
  return Object.assign({}, ...Object.values(localeMessages));
};
