import React from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

import FieldContainer from '../FieldContainer';

export const NormalTextField = ({ id, placeholder, value, onChange, icon, disabled, error, helperText }) => (
  <TextField
    sx={{
      "& .MuiOutlinedInput-root": {
        borderRadius: '10px', fontSize: '16px', lineHeight: 1.43,
        backgroundColor: error ? "#fff4f4" : "transparent",
        "& fieldset": {
          borderColor: "#eef0f2",
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: "#bad4dd",
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          border: `1px solid ${error ? '#E80000' : '#bad4dd'}`,
          boxShadow: error ? "0px 0px 0px 2px rgba(232, 0, 0, 0.25)"
            : "0 0 0 2px rgba(186, 212, 221, 0.25)",
        },
      },
    }}
    fullWidth
    id={id}
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    InputProps={{
      endAdornment: icon ? (
        <InputAdornment position="end" sx={{ width: '24px', height: '24px' }}>
          {icon}
        </InputAdornment>
      ) : null
    }}
    disabled={disabled}
    error={error}
    helperText={error ? helperText : ''}
    FormHelperTextProps={{
      sx: {
        color: 'red',
        fontSize: '14px',
        marginLeft: '10px',
      },
    }}
  />
);

const InputTextField = ({ formikProps, proportion, id, label, labelColor, placeholder, icon, disabled }) => {
  const { errors, touched, getFieldProps } = formikProps;
  const errorState = Boolean(touched[id] && errors[id])

  return (
    <Stack spacing={1}>
      <FieldContainer proportion={proportion} label={label} labelColor={labelColor} errorState={errorState}>
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: '10px', fontSize: '16px', lineHeight: 1.43,
              backgroundColor: errorState ? "#fff4f4" : "transparent",
              "& fieldset": {
                borderColor: "#eef0f2",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#bad4dd",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${errorState ? '#E80000' : '#bad4dd'}`,
                boxShadow: errorState ? "0px 0px 0px 2px rgba(232, 0, 0, 0.25)"
                  : "0 0 0 2px rgba(186, 212, 221, 0.25)",
              },
            },
          }}
          fullWidth
          id={id}
          placeholder={placeholder}
          {...getFieldProps(id)}
          error={errorState}
          helperText={touched[id] && errors[id]}
          FormHelperTextProps={{
            sx: {
              color: 'red',
              fontSize: '14px',
              marginLeft: '10px',
            },
          }}
          InputProps={{
            endAdornment: icon ? (
              <InputAdornment position="end" sx={{ width: '24px', height: '24px' }}>
                {icon}
              </InputAdornment>
            ) : null
          }}
          disabled={disabled}
        />
      </FieldContainer>
    </Stack>
  );
};

export default InputTextField;
