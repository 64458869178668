import React from 'react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import FieldContainer from '../FieldContainer';

const TextAreaField = ({ formikProps, proportion, id, label, labelColor, placeholder, minRows, maxRows, disabled }) => {
  const { errors, touched, getFieldProps } = formikProps;
  const errorState = Boolean(touched[id] && errors[id])

  return (
    <Stack spacing={1}>
      <FieldContainer proportion={proportion} label={label} labelColor={labelColor} errorState={errorState}>
        <TextField
          sx={{
            "& .MuiOutlinedInput-root": {
              borderRadius: '10px', fontSize: '16px', lineHeight: 1.43,
              backgroundColor: errorState ? "#fff4f4" : "transparent",
              "& fieldset": {
                borderColor: "#eef0f2",
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "#bad4dd",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: `1px solid ${errorState ? '#E80000' : '#bad4dd'}`,
                boxShadow: errorState ? "0px 0px 0px 2px rgba(232, 0, 0, 0.25)"
                  : "0 0 0 2px rgba(186, 212, 221, 0.25)",
              },
            },
          }}
          fullWidth
          id={id}
          placeholder={placeholder}
          multiline
          minRows={minRows}
          maxRows={maxRows}
          {...getFieldProps(id)}
          error={errorState}
          helperText={touched[id] && errors[id]}
          disabled={disabled}
        />
      </FieldContainer>
    </Stack>
  );
};

export default TextAreaField;
