import { useState, useEffect } from "react";
import { useWebSocket } from "hook/WebSocketContext";
import { useAuth } from "../../hook/AuthContext";
import { FormattedMessage, useIntl } from "react-intl";

import { useTheme } from "@mui/material";

import {
  Box,
  Button,
  Typography,
  TextField,
  Paper,
  Skeleton,
  Checkbox,
  InputAdornment,
  IconButton,
  FormHelperText,
} from "@mui/material";

import { ReactComponent as DisLikeIcon } from "assets/img/icon/icn_dislike.svg";
import { ReactComponent as LikeIcon } from "assets/img/icon/icn_like.svg";
import { ReactComponent as InputEditIcon } from "assets/img/icon/icn_input_edit_default.svg";
import { ReactComponent as AddIcon } from "assets/img/icon/btn_add.svg";
import { ReactComponent as RemovedIcon } from "assets/img/icon/btn_removed.svg";
/* import { ReactComponent as ClearIcon } from "assets/img/icon/btn_input_clear.svg"; */

import { careInfoTitle, careInfoData, careInfoListStyle } from "./style";

const CareInfo = ({
  elderData,
  setElderData,
  isLoading,
  wsData,
  setWsData,
  homeGroupId,
  userId,
  isEditingCare,
  isEditingElder,
  setIsEditingCare,
  handleEditClickCare,
}) => {
  const ws = useWebSocket();
  const { token } = useAuth();
  const theme = useTheme();
  const intl = useIntl();
  const [hasError, setHasError] = useState(false);

  const [medicalData, setMedicalData] = useState({
    medicalHistory: [],
    medicalHistoryList: [],
    medDiagnosisMalignantTumors: "",
    medDiagnosisSurgery: "",
    medDiagnosisOther: "",
    allergies: [],
    foodPreferences: [],
    foodTaboos: [],
    medTreatmentInfo: "",
  });

  const [originalMedicalData, setOriginalMedicalData] = useState({});
  const [newAllergy, setNewAllergy] = useState("");
  const [newFoodPreferences, setNewFoodPreferences] = useState("");
  const [newFoodTaboos, setNewFoodTaboos] = useState("");
  const [malignantTumorsError, setMalignantTumorsError] = useState(false);
  const [surgeryError, setSurgeryError] = useState(false);
  const [otherError, setOtherError] = useState(false);

  useEffect(() => {
    if (elderData) {
      setMedicalData({
        medicalHistory: elderData.medicalHistory,
        medicalHistoryList: elderData.medicalHistoryList,
        medDiagnosisMalignantTumors: elderData.medDiagnosisMalignantTumors,
        medDiagnosisSurgery: elderData.medDiagnosisSurgery,
        medDiagnosisOther: elderData.medDiagnosisOther,
        allergies: elderData.allergies,
        foodPreferences: elderData.foodPreferences,
        foodTaboos: elderData.foodTaboos,
        medTreatmentInfo: elderData.medTreatmentInfo,
      });
      setOriginalMedicalData(elderData);
    }
  }, [elderData]);

  // save prev data
  /* const handleMedicalHistoryChange = (event, medicalId) => {
    const checked = event.target.checked;
    setMedicalData((prevData) => {
      const updatedMedicalHistory = checked
        ? [...prevData.medicalHistory, medicalId]
        : prevData.medicalHistory.filter((id) => id !== medicalId);

      return {
        ...prevData,
        medicalHistory: updatedMedicalHistory,
      };
    });
  }; */

  // clear prev data
  const handleMedicalHistoryChange = (event, medicalId) => {
    const checked = event.target.checked;

    setMedicalData((prevData) => {
      let updatedMedicalHistory = [];

      if (checked) {
        updatedMedicalHistory = [...prevData.medicalHistory, medicalId];
      } else {
        updatedMedicalHistory = prevData.medicalHistory.filter(
          (id) => id !== medicalId
        );

        if (medicalId === 13) {
          prevData.medDiagnosisMalignantTumors = "";
          setMalignantTumorsError(false);
        } else if (medicalId === 14) {
          prevData.medDiagnosisSurgery = "";
          setSurgeryError(false);
        } else if (medicalId === 15) {
          prevData.medDiagnosisOther = "";
          setOtherError(false);
        }
      }

      const errorStatus = checkForErrors(updatedMedicalHistory, prevData);
      setHasError(errorStatus);

      return {
        ...prevData,
        medicalHistory: updatedMedicalHistory,
        medDiagnosisMalignantTumors: prevData.medDiagnosisMalignantTumors,
        medDiagnosisSurgery: prevData.medDiagnosisSurgery,
        medDiagnosisOther: prevData.medDiagnosisOther,
      };
    });
  };

  const handleInputChange = (medicalId, value) => {
    setMedicalData((prevData) => {
      const updatedData = { ...prevData };

      if (medicalId === 13) {
        updatedData.medDiagnosisMalignantTumors = value;
        setMalignantTumorsError(false);
      } else if (medicalId === 14) {
        updatedData.medDiagnosisSurgery = value;
        setSurgeryError(false);
      } else if (medicalId === 15) {
        updatedData.medDiagnosisOther = value;
        setOtherError(false);
      }

      const errorStatus = checkForErrors(prevData.medicalHistory, updatedData);
      setHasError(errorStatus);

      return updatedData;
    });
  };

  const checkForErrors = (medicalHistory, medicalData) => {
    let errorFound = false;

    if (
      medicalHistory.includes(13) &&
      medicalData.medDiagnosisMalignantTumors.length > 100
    ) {
      errorFound = true;
      setMalignantTumorsError(true);
    }
    if (
      medicalHistory.includes(14) &&
      medicalData.medDiagnosisSurgery.length > 100
    ) {
      errorFound = true;
      setSurgeryError(true);
    }
    if (
      medicalHistory.includes(15) &&
      medicalData.medDiagnosisOther.length > 100
    ) {
      errorFound = true;
      setOtherError(true);
    }

    setHasError(errorFound);
    return errorFound;
  };

  const filteredMedicalList =
    Array.isArray(medicalData.medicalHistoryList) &&
      Array.isArray(medicalData.medicalHistory)
      ? medicalData.medicalHistoryList.filter((item) =>
        medicalData.medicalHistory.includes(item.medicalId)
      )
      : [];

  const handleAddFoodTaboos = () => {
    if (newFoodTaboos.trim()) {
      setMedicalData((prevData) => ({
        ...prevData,
        foodTaboos: [...prevData.foodTaboos, newFoodTaboos.trim()],
      }));
      setNewFoodTaboos("");
    }
  };

  const handleRemoveFoodTaboos = (index) => {
    setMedicalData((prevData) => ({
      ...prevData,
      foodTaboos: prevData.foodTaboos.filter((_, i) => i !== index),
    }));
  };

  const handleAddFoodPreferences = () => {
    if (newFoodPreferences.trim()) {
      setMedicalData((prevData) => ({
        ...prevData,
        foodPreferences: [
          ...prevData.foodPreferences,
          newFoodPreferences.trim(),
        ],
      }));
      setNewFoodPreferences("");
    }
  };

  const handleRemoveFoodPreferences = (index) => {
    setMedicalData((prevData) => ({
      ...prevData,
      foodPreferences: prevData.foodPreferences.filter((_, i) => i !== index),
    }));
  };

  const handleAddAllergy = () => {
    if (newAllergy.trim()) {
      setMedicalData((prevData) => ({
        ...prevData,
        allergies: [...prevData.allergies, newAllergy.trim()],
      }));
      setNewAllergy("");
    }
  };

  const handleRemoveAllergy = (index) => {
    setMedicalData((prevData) => ({
      ...prevData,
      allergies: prevData.allergies.filter((_, i) => i !== index),
    }));
  };

  /*   const handleClear = () => {
    setMedicalData((prevData) => ({
      ...prevData,
      medTreatmentInfo: "",
    }));
  }; */

  const handleMedTreatmentInfoChange = (e) => {
    setMedicalData((prevData) => ({
      ...prevData,
      medTreatmentInfo: e.target.value,
    }));
  };

  const handleSave = () => {
    const { medicalHistoryList, ...filteredMedicalData } = medicalData;

    const infoUpdateData = {
      resource: `/users/${userId}`,
      verb: "update",
      accessToken: token,
      data: {
        filter: {
          homeGroupId: homeGroupId,
        },
        userData: filteredMedicalData,
      },
    };

    ws.send(JSON.stringify(infoUpdateData));

    if (!hasError) {
      setIsEditingCare(false);
      setOriginalMedicalData(filteredMedicalData);
    }
  };

  const handleCancel = () => {
    setMedicalData((prevData) => ({
      ...prevData,
      ...originalMedicalData,
    }));
    setIsEditingCare(false);
  };

  useEffect(() => {
    if (wsData.updateTag === "user_create" && wsData.updateData) {
      setMedicalData(wsData.updateData);
    }
  }, [elderData]);

  /* console.log(medicalData); */
  /* console.log(hasError); */

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <Box sx={{ marginTop: "10.5px" }}>
          {isEditingCare ? (
            <>
              <Typography sx={careInfoTitle} marginBottom="10px">
                <FormattedMessage id="app.careInformation.careInfo.fillElderInfo" />
              </Typography>
              <Typography sx={careInfoTitle}><FormattedMessage id="app.careInformation.careInfo.pastMedicalHistory" />:</Typography>

              <div style={careInfoListStyle}>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    marginLeft: "-12px",
                    marginTop: "-12px",
                  }}
                >
                  {Array.isArray(elderData.medicalHistoryList) &&
                    elderData.medicalHistoryList
                      .sort((a, b) => a.medicalId - b.medicalId)
                      .map((item, index) => {
                        const isSpecialChecked =
                          [13, 14, 15].includes(item.medicalId) &&
                          medicalData.medicalHistory.includes(item.medicalId);

                        return (
                          <div
                            key={index}
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              maxWidth: isSpecialChecked
                                ? "100%"
                                : "calc(33% - 10px)",
                              whiteSpace: "nowrap",
                              overflowY: "auto",
                              flexDirection: "row",
                              width: isSpecialChecked ? "100%" : "auto",
                            }}
                          >
                            {/* Checkbox */}
                            <Checkbox
                              checked={medicalData.medicalHistory.includes(
                                item.medicalId
                              )}
                              onChange={(e) =>
                                handleMedicalHistoryChange(e, item.medicalId)
                              }
                              sx={{
                                "&.Mui-checked": { color: "#0087a9" },
                                "& .MuiSvgIcon-root": {
                                  fontSize: 24,
                                },
                                color: "#eef0f2",
                              }}
                            />
                            <div
                              style={{
                                fontSize: "16px",
                                color: "#737791",
                                marginTop: "10px",
                              }}
                            >
                              {item.medicalChName}
                            </div>

                            {isSpecialChecked && (
                              <div>
                                <TextField
                                  placeholder={
                                    item.medicalId === 13
                                      ? intl.formatMessage({ id: 'app.careInformation.careInfo.tumorName' })
                                      : item.medicalId === 14
                                        ? intl.formatMessage({ id: "app.careInformation.careInfo.nameOfSurgery" })
                                        : item.medicalId === 15
                                          ? intl.formatMessage({ id: "app.common.other" })
                                          : ""
                                  }
                                  value={
                                    item.medicalId === 13
                                      ? medicalData.medDiagnosisMalignantTumors
                                      : item.medicalId === 14
                                        ? medicalData.medDiagnosisSurgery
                                        : item.medicalId === 15
                                          ? medicalData.medDiagnosisOther
                                          : ""
                                  }
                                  onChange={(e) =>
                                    handleInputChange(
                                      item.medicalId,
                                      e.target.value
                                    )
                                  }
                                  multiline
                                  rows={1}
                                  rowsMax={2}
                                  error={
                                    item.medicalId === 13
                                      ? malignantTumorsError
                                      : item.medicalId === 14
                                        ? surgeryError
                                        : item.medicalId === 15
                                          ? otherError
                                          : false
                                  }
                                  sx={{
                                    marginLeft: "24px",
                                    marginTop: "1px",
                                    width: "395px",
                                    borderColor: "#eef0f2",
                                    "& .MuiOutlinedInput-root": {
                                      backgroundColor:
                                        (item.medicalId === 13 &&
                                          malignantTumorsError) ||
                                          (item.medicalId === 14 &&
                                            surgeryError) ||
                                          (item.medicalId === 15 && otherError)
                                          ? "#fff4f4"
                                          : "transparent",
                                      borderRadius: "10px",
                                      fontSize: "16px",
                                      color: "#737791",
                                      "& fieldset": {
                                        borderColor:
                                          (item.medicalId === 13 &&
                                            malignantTumorsError) ||
                                            (item.medicalId === 14 &&
                                              surgeryError) ||
                                            (item.medicalId === 15 && otherError)
                                            ? "#E80000"
                                            : "#eef0f2",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#bad4dd",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          (item.medicalId === 13 &&
                                            malignantTumorsError) ||
                                            (item.medicalId === 14 &&
                                              surgeryError) ||
                                            (item.medicalId === 15 && otherError)
                                            ? "#E80000"
                                            : "#bad4dd",
                                        boxShadow:
                                          (item.medicalId === 13 &&
                                            malignantTumorsError) ||
                                            (item.medicalId === 14 &&
                                              surgeryError) ||
                                            (item.medicalId === 15 && otherError)
                                            ? "0 0 0 2px rgba(232, 0, 0, 0.25)"
                                            : "0 0 0 2px rgba(186, 212, 221, 0.25)",
                                      },
                                    },
                                  }}
                                />
                                {item.medicalId === 13 &&
                                  malignantTumorsError && (
                                    <FormHelperText
                                      sx={{
                                        color: "red",
                                        fontSize: "14px",
                                        marginLeft: "24px",
                                      }}
                                    >
                                      <FormattedMessage id="app.errorMessage.noMoreThanWords" values={{ value: '100' }} />
                                    </FormHelperText>
                                  )}

                                {item.medicalId === 14 && surgeryError && (
                                  <FormHelperText
                                    sx={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <FormattedMessage id="app.errorMessage.noMoreThanWords" values={{ value: '100' }} />
                                  </FormHelperText>
                                )}

                                {item.medicalId === 15 && otherError && (
                                  <FormHelperText
                                    sx={{
                                      color: "red",
                                      fontSize: "14px",
                                      marginLeft: "24px",
                                    }}
                                  >
                                    <FormattedMessage id="app.errorMessage.noMoreThanWords" values={{ value: '100' }} />
                                  </FormHelperText>
                                )}
                              </div>
                            )}
                          </div>
                        );
                      })}
                </div>
              </div>
            </>
          ) : (
            <>
              <Typography sx={{ ...careInfoTitle, marginTop: "22px" }}>
                <FormattedMessage id="app.careInformation.careInfo.pastMedicalHistory" />:
              </Typography>
              <div
                style={{
                  ...careInfoListStyle,
                  /* maxWidth: "calc(100% - 32px)", */
                  paddingTop: "28px",
                }}
              >
                {isLoading ? (
                  <Skeleton variant="text" width="60%" height={44} />
                ) : (
                  <>
                    {filteredMedicalList
                      .sort((a, b) => a.medicalId - b.medicalId)
                      .map((item, index) => (
                        <div
                          key={index}
                          style={{
                            ...careInfoData,
                            maxWidth: "calc(100% - 32px)",
                          }}
                        >
                          {item.medicalChName}
                          {medicalData.medicalHistory.includes(
                            item.medicalId
                          ) && (
                              <span style={{ fontSize: "14px" }}>
                                {item.medicalId === 13 &&
                                  medicalData.medDiagnosisMalignantTumors.trim() !==
                                  ""
                                  ? ` : ${medicalData.medDiagnosisMalignantTumors}`
                                  : item.medicalId === 14 &&
                                    medicalData.medDiagnosisSurgery.trim() !== ""
                                    ? ` : ${medicalData.medDiagnosisSurgery}`
                                    : item.medicalId === 15 &&
                                      medicalData.medDiagnosisOther.trim() !== ""
                                      ? ` : ${medicalData.medDiagnosisOther}`
                                      : ""}
                              </span>
                            )}
                        </div>
                      ))}
                  </>
                )}
              </div>
            </>
          )}
        </Box>
        <Box sx={{ marginTop: "24px" }}>
          <Typography sx={careInfoTitle}><FormattedMessage id="app.careInformation.careInfo.allergicSubstances" /></Typography>
          {isEditingCare ? (
            <div style={careInfoListStyle}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <TextField
                    placeholder={intl.formatMessage({ id: 'app.careInformation.careInfo.pleaseEnterWhatYourElderIsAllergicTo' })}
                    value={newAllergy}
                    onChange={(e) => setNewAllergy(e.target.value)}
                    // key enter to add item
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && newAllergy.trim() !== "") {
                        handleAddAllergy();
                        e.preventDefault();
                      }
                    }}
                    multiline
                    rows={1}
                    sx={{
                      width: "384px",
                      borderColor: "#eef0f2",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        fontSize: "16px",
                        color: "#737791",
                        "& fieldset": {
                          borderColor: "#eef0f2",
                        },
                        "&:hover fieldset": {
                          borderColor: "#bad4dd",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#bad4dd",
                          boxShadow: "0 0 0 2px rgba(186, 212, 221, 0.25)",
                        },
                        paddingTop: "10px",
                        paddingBottom: "12px",
                      },
                    }}
                  />
                  <IconButton onClick={handleAddAllergy}>
                    <AddIcon />
                  </IconButton>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {Array.isArray(medicalData.allergies) &&
                    medicalData.allergies.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          paddingTop: "12px",
                        }}
                      >
                        <div style={careInfoData}>{item}</div>
                        <IconButton onClick={() => handleRemoveAllergy(index)}>
                          <RemovedIcon />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              </Box>
            </div>
          ) : (
            <div style={careInfoListStyle}>
              {isLoading ? (
                <Skeleton variant="text" width="60%" height={44} />
              ) : (
                <>
                  {Array.isArray(medicalData.allergies) &&
                    medicalData.allergies.map((item, index) => (
                      <div key={index} style={careInfoData}>
                        {item}
                      </div>
                    ))}
                </>
              )}
            </div>
          )}
        </Box>

        <Box sx={{ marginTop: "24px" }}>
          <Typography sx={careInfoTitle}><FormattedMessage id="app.careInformation.careInfo.dietaryPreferences" /></Typography>
          {/* 喜歡的 */}

          {isEditingCare ? (
            <div style={careInfoListStyle}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    gap: "10px",
                  }}
                >
                  <LikeIcon />

                  <TextField
                    placeholder={intl.formatMessage({ id: 'app.careInformation.careInfo.pleaseEnterWhatYourEldersLikeToEat' })}
                    value={newFoodPreferences}
                    onChange={(e) => setNewFoodPreferences(e.target.value)}
                    // key enter to add item
                    onKeyDown={(e) => {
                      if (
                        e.key === "Enter" &&
                        newFoodPreferences.trim() !== ""
                      ) {
                        handleAddFoodPreferences();
                        e.preventDefault();
                      }
                    }}
                    multiline
                    rows={1}
                    sx={{
                      width: "384px",
                      borderColor: "#eef0f2",
                      "& .MuiOutlinedInput-root": {
                        borderRadius: "10px",
                        fontSize: "16px",
                        color: "#737791",
                        "& fieldset": {
                          borderColor: "#eef0f2",
                        },
                        "&:hover fieldset": {
                          borderColor: "#bad4dd",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#bad4dd",
                          boxShadow: "0 0 0 2px rgba(186, 212, 221, 0.25)",
                        },
                        paddingTop: "10px",
                        paddingBottom: "12px",
                      },
                    }}
                  />
                  <IconButton
                    onClick={handleAddFoodPreferences}
                    sx={{ marginLeft: "-10px" }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {Array.isArray(medicalData.foodPreferences) &&
                    medicalData.foodPreferences.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          paddingTop: "12px",
                        }}
                      >
                        <div style={careInfoData}>{item}</div>
                        <IconButton
                          onClick={() => handleRemoveFoodPreferences(index)}
                        >
                          <RemovedIcon />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              </Box>
            </div>
          ) : (
            <div style={careInfoListStyle}>
              {isLoading ? (
                <Skeleton variant="text" width="60%" height={44} />
              ) : (
                <>
                  <LikeIcon />
                  {Array.isArray(medicalData.foodPreferences) &&
                    medicalData.foodPreferences.map((item, index) => (
                      <div key={index} style={careInfoData}>
                        {item}
                      </div>
                    ))}
                </>
              )}
            </div>
          )}

          {/* 不喜歡的 */}
          {isEditingCare ? (
            <div style={careInfoListStyle}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginBottom: "20px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "10px",
                    gap: "10px",
                  }}
                >
                  <DisLikeIcon />
                  <TextField
                    placeholder={intl.formatMessage({ id: 'app.careInformation.careInfo.pleaseEnterWhatYourEldersDontLikeToEat' })}
                    value={newFoodTaboos}
                    onChange={(e) => setNewFoodTaboos(e.target.value)}
                    // key enter to add item
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && newFoodTaboos.trim() !== "") {
                        handleAddFoodTaboos();
                        e.preventDefault();
                      }
                    }}
                    multiline
                    rows={1}
                    sx={{
                      width: "384px",
                      borderColor: "#eef0f2",
                      "& .MuiOutlinedInput-root": {
                        fontSize: "16px",
                        color: "#737791",
                        borderRadius: "10px",
                        fontSize: "16px",
                        color: "#737791",
                        "& fieldset": {
                          borderColor: "#eef0f2",
                        },
                        "&:hover fieldset": {
                          borderColor: "#bad4dd",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "#bad4dd",
                          boxShadow: "0 0 0 2px rgba(186, 212, 221, 0.25)",
                        },
                        paddingTop: "10px",
                        paddingBottom: "12px",
                      },
                    }}
                  />
                  <IconButton
                    onClick={handleAddFoodTaboos}
                    sx={{ marginLeft: "-10px" }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                    alignItems: "center",
                  }}
                >
                  {Array.isArray(medicalData.foodTaboos) &&
                    medicalData.foodTaboos.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          display: "inline-flex",
                          alignItems: "center",
                          paddingTop: "12px",
                        }}
                      >
                        <div style={careInfoData}>{item}</div>
                        <IconButton
                          onClick={() => handleRemoveFoodTaboos(index)}
                        >
                          <RemovedIcon />
                        </IconButton>
                      </Box>
                    ))}
                </Box>
              </Box>
            </div>
          ) : (
            <div style={careInfoListStyle}>
              {isLoading ? (
                <Skeleton variant="text" width="60%" height={44} />
              ) : (
                <>
                  <DisLikeIcon />
                  {Array.isArray(medicalData.foodTaboos) &&
                    medicalData.foodTaboos.map((item, index) => (
                      <div key={index} style={careInfoData}>
                        {item}
                      </div>
                    ))}
                </>
              )}
            </div>
          )}
        </Box>
        <Box sx={{ marginTop: "24px" }}>
          <Typography sx={careInfoTitle}>
            <FormattedMessage id="app.careInformation.careInfo.medicalInformation" />
          </Typography>
          {isEditingCare ? (
            <>
              <Box sx={{ marginTop: "10px" }}>
                <TextField
                  value={medicalData.medTreatmentInfo}
                  onChange={handleMedTreatmentInfoChange}
                  multiline
                  rows={3}
                  sx={{
                    width: "100%",
                    borderColor: "#eef0f2",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: "10px",
                      fontSize: "16px",
                      color: "#737791",
                      "& fieldset": {
                        borderColor: "#eef0f2",
                      },
                      "&:hover fieldset": {
                        borderColor: "#bad4dd",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#bad4dd",
                        boxShadow: "0 0 0 2px rgba(186, 212, 221, 0.25)",
                      },
                    },
                    "& textarea": {
                      overflowY: "auto",
                      maxHeight: "80px",
                      "&::-webkit-scrollbar": {
                        width: "4px",
                      },
                      "&::-webkit-scrollbar-thumb": {
                        backgroundColor: "#cccccc",
                        borderRadius: "999px",
                      },
                      /* "&::-webkit-scrollbar-track": {
                          backgroundColor: "#f0f0f0",
                        }, */
                    },
                  }}
                /* InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        sx={{ position: "absolute", top: 20, right: 16 }}
                      >
                        <IconButton onClick={handleClear}>
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }} */
                />
              </Box>
            </>
          ) : (
            <>
              <Paper
                elevation={0}
                sx={{
                  backgroundColor: "#f9fafb",
                  borderRadius: "10px",
                  padding: "8px 16px",
                  color: "#737791",
                  fontSize: "16px",
                  marginTop: "10px",
                }}
              >
                {isLoading ? (
                  <Skeleton
                    variant="text"
                    width="100%"
                    height={100}
                    sx={{ marginBottom: "8px" }}
                  />
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ marginBottom: "8px", whiteSpace: "pre-wrap" }}
                  >
                    {medicalData.medTreatmentInfo || "-"}
                  </Typography>
                )}
              </Paper>
            </>
          )}
        </Box>
        {isEditingCare ? (
          <>
            <Button
              variant="outlined"
              onClick={handleCancel}
              style={{
                position: "absolute",
                right: 80,
                top: 0,
                width: "60px",
                height: "40px",
                borderRadius: "10px",
                padding: "8px 16px",
                border: "1px solid #0087a9",
                color: "#0087a9",
                fontWeight: 600,
              }}
            >
              <FormattedMessage id="app.common.cancel" />
            </Button>
            <Button
              variant="outlined"
              onClick={handleSave}
              disabled={hasError}
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                width: "64px",
                height: "40px",
                borderRadius: "10px",
                padding: "8px 16px",
                border: "1px solid #0087a9",
                color: "#ffffff",
                backgroundColor: "#0087a9",
                fontWeight: 600,
              }}
            >
              <FormattedMessage id="app.common.save" />
            </Button>
          </>
        ) : (
          <Button
            variant="outlined"
            startIcon={<InputEditIcon />}
            onClick={handleEditClickCare}
            disabled={isEditingElder || isLoading}
            style={{
              position: "absolute",
              right: 0,
              top: -10,
              width: "92px",
              height: "40px",
              borderRadius: "10px",
              padding: "8px 16px",
              border: "1px solid #424962",
              color: "#424962",
              fontWeight: 600,
            }}
          >
            <FormattedMessage id="app.common.edit" />
          </Button>
        )}
      </Box>
    </>
  );
};

export default CareInfo;
