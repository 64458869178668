import React, { useState, useEffect } from "react";
import summaryStyle from '../summary.module.scss';
import { cloneDeep, isNil } from 'lodash';
import { useMediaQuery, useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import wellNote from "assets/img/icon/icn_normal.svg";
import errorNote from "assets/img/icon/icn_abnormal.svg";

const UserCard = (props) => {
  const { card, openSpinner, setOpenSpinner } = props;

  useEffect(() => {
    // ...
  }, [card]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1280));

  return (
    <>
      {/* <Grid container alignItems="center" justifyContent="space-evenly" sx={{p: 1}}> */}
      {card.map((user, index) => (
        <div className={summaryStyle['usercardContainer']}
          key={index} style={{ minWidth:'280px', marginRight: (isSmallScreen? '20px': '5px'), // BAD4DD
          background: (user.selected? 'white': ''), border: '1px solid #b8d3db'}}>
          <div className={summaryStyle['usercardBox']}>
            <div className={summaryStyle['usercardPhoto']}>
              <div className={summaryStyle['profileImage']} 
                style={{ width: '80px', height: '80px',
                  borderColor:(user.status.total==='abnormal'? '#F89898': user.status.total==='normal'?'#94DEB4':'#FFFFFF'), 
                  backgroundImage: `url(${user.photo})`}}>
              </div>
              {user.note && 
                <img src={user.status.total==='abnormal'? errorNote: user.status.total==='normal'? wellNote:''}
                  className={summaryStyle['profileNote']} />
              }
            </div>
            
            <div className={summaryStyle['usercardInfo']}>
              <div>
                <Typography variant="h6" component="div" color="#424962" style={{fontWeight:"bold", marginBottom: '10px'}}>
                  {user.name}
                </Typography>
                <div style={{display: 'flex'}}>
                  <Typography variant='h7' component="div" color="#737791" >
                    {user.age} 歲
                  </Typography>
                  <Typography component="div" color="#BAD4DD" style={{margin: '0px 5px'}}>|</Typography>
                  <Typography variant='h7' component="div" color="#737791" >
                    {user.gender}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default UserCard;
