export const panelTheme = {
  padding: "20px 20px 10px 20px",
  position: "relative",
  /*   border: '1px solid',
  borderTop: 'none', */
  borderRadius: "0px 20px 20px 20px",
  borderColor: "rgba(0, 0, 0, 0.09)",
  backgroundColor: "white",
};

export const elderInfoTitle = {
  fontSize: "16px",
  color: "#424962",
  fontWeight: 600,
  marginBottom: "12px",
};

export const elderInfoData = {
  fontSize: "16px",
  color: "#737791",
  fontWeight: "normal",
  marginBottom: "12px",
};

export const careInfoTitle = {
  color: "#424962",
  fontSize: "16px",
  fontWeight: 600,
};

export const careInfoData = {
  padding: "10px 16px",
  backgroundColor: "#e9f4fa",
  borderRadius: "999px",
  fontSize: "14px",
  color: "#0087a9",
  fontWeight: 600,
  /* textAlign: "center", */
  whiteSpace: "normal",
  wordWrap: "break-word",
  overflow: "hidden",
  maxWidth: "100%",
};

export const careInfoListStyle = {
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  paddingTop: "20px",
  gap: "24px",
};
