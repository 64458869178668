import React from 'react'

import Box from "@mui/material/Box";
import Grid from '@mui/material/Grid';
import Typography from "@mui/material/Typography";

import DevicesCard from '../components/DevicesCard';

const RoomFields = ({
  currentProsessState,
  devicesData,
  setDevicesData,
  roomFields,
  openSpinner,
  setOpenSpinner
}) => {
  const roomFieldsItems = roomFields.devicesIds.map((itemId) => devicesData.devicesInfo.filter((item) => item.id === itemId)[0]);
  return (
    <Box sx={{ minWidth: 300, margin: '0 20px', height: '100%' }}>
      <div>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs zeroMinWidth>
            <Box sx={{ margin: '20px 0 24px 15px', padding: '10px 16px' }}>
              <Typography color="#0087a9" fontSize="18px" fontWeight="600" lineHeight="1.11">{roomFields.name}</Typography>
            </Box>
          </Grid>
        </Grid>
        {roomFieldsItems.map((item) => (
          <DevicesCard
            key={`viewPage-${item.id}`}
            currentProsessState={currentProsessState}
            devicesData={devicesData}
            setDevicesData={setDevicesData}
            roomFieldName={roomFields.name}
            devicesCardContent={item}
            openSpinner={openSpinner}
            setOpenSpinner={setOpenSpinner}
          />
        ))}
      </div>
    </Box>
  )
}

const ViewPage = ({
  currentProsessState,
  devicesData,
  setDevicesData,
  openSpinner,
  setOpenSpinner,
}) => (
  <Box
    sx={{
      display: 'flex',
      gap: 2,
      padding: 2,
      whiteSpace: 'nowrap',
      overflowX: 'auto',
      '&::-webkit-scrollbar': {
        height: '4px',
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: '#bad4dd',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-track': {
        backgroundColor: 'none',
      },
    }}
  >
    {devicesData && devicesData[0].roomFieldsOrder.map((columnId) => {
      const roomFields = devicesData[0].roomFields.find((item) => item.id === columnId);
      return (
        roomFields && (
          <RoomFields
            key={columnId}
            currentProsessState={currentProsessState}
            devicesData={devicesData[0]}
            setDevicesData={setDevicesData}
            roomFields={roomFields}
            openSpinner={openSpinner}
            setOpenSpinner={setOpenSpinner}
          />
        )
      );
    })}
  </Box >
)

export default ViewPage